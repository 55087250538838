import React from "react";
import { useTranslation } from "react-i18next";
import ReactSwitch from "react-switch";
import { useSnapshot } from "valtio";

import {
    designStore,
    generalConfigStore,
    setIsHeaderOrderTakingActive,
    setIsOrderTakingPromoCodeActive,
    setIsOrderTakingProductInformationActive,
    setIsOrderTakingSelectedCategoryTitleActive,
    setIsOrderTakingTagsActive,
    setIsOrderTakingTopBannerActive,
    setIsPageHeaderActive,
    setIsPageProductAccessIconActive,
    setIsPagePromoCodeActive,
    setIsPageSelectedCategoryTitleActive,
    setIsPageTagsActive,
    setIsPageTopBannerActive,
    setIsPageUserAccountActive,
    setIsUserAccountOrderTakingActive,
    setIsNutriscoreActive,
    setIsCommentArticleActive,
    setIsCommentRecoveryActive,
    setIsFreeCommentActive,
    setIsPageCommentArticleActive,
    setIsPageCommentRecoveryActive,
    setIsPageFreeCommentActive,
    setIsOrderTakingCaloryActive,
    setPageOrderTaking,
} from "@store";
import { store } from "@components/VerticalLayout/store";
import { POS_EDITOR_TCPOS } from "@constants/index";

export function Functionalities(): JSX.Element {
    const { t } = useTranslation();
    const {
        isCustomerAccountActive,
        isOrderTakingTopBannerActive,
        isOrderTakingTagsActive,
        isOrderTakingProductInformationActive,
        isOrderTakingSelectedCategoryTitleActive,
        isCustomerAccountOrderTakingActive,
        isAllergenOrderTakingActive,
        isOrderTakingPromoCodeActive,
        isNutriscoreActive,
        isCommentArticleActive,
        isFreeCommentActive,
        isCommentRecoveryActive,
        isOrderTakingCaloryActive,
        project: { template, shopId },
    } = useSnapshot(generalConfigStore);
    const { isUserAccountOrderTakingActive } = useSnapshot(designStore);
    const { posEditor } = useSnapshot(store);
    function handleIsClientActiveSwitchOnClickEvent() {
        setIsUserAccountOrderTakingActive(!isUserAccountOrderTakingActive);
        setIsPageUserAccountActive(
            !isUserAccountOrderTakingActive,
            "orderTaking"
        );
        if (
            [
                !isUserAccountOrderTakingActive,
                isCustomerAccountOrderTakingActive,
                isAllergenOrderTakingActive,
            ].every((currentValue) => currentValue === false) === true
        ) {
            setIsHeaderOrderTakingActive(false);
            setIsPageHeaderActive(false, "orderTaking");
        } else {
            setIsHeaderOrderTakingActive(true);
            setIsPageHeaderActive(true, "orderTaking");
        }
    }
    function handleIsTopBannerActiveSwitchOnClickEvent() {
        setIsOrderTakingTopBannerActive(!isOrderTakingTopBannerActive);
        setIsPageTopBannerActive(!isOrderTakingTopBannerActive, "orderTaking");
    }
    function handleIsCommentArticleActiveSwitchOnClickEvent() {
        setIsCommentArticleActive(!isCommentArticleActive);
        setIsPageCommentArticleActive(!isCommentArticleActive);

        setIsFreeCommentActive(!isCommentArticleActive);
        setIsPageFreeCommentActive(!isCommentArticleActive);
        setIsCommentRecoveryActive(!isCommentArticleActive);
        setIsPageCommentRecoveryActive(!isCommentArticleActive);
    }
    return (
        <div className="d-flex flex-column p-4">
            <span
                style={{
                    font: "normal normal 600 24px/40px Nunito Sans",
                }}
            >
                {t("Design")}
            </span>
            <div className="d-flex flex-row justify-content-between align-items-center">
                {t("Display the category's advertising banner")}

                <ReactSwitch
                    className="ksk_chk_expertTakeOrderFeaturesBanner"
                    uncheckedIcon={false}
                    checkedIcon={false}
                    handleDiameter={26}
                    offColor="#f7b4b8"
                    offHandleColor="#E30613"
                    checked={isOrderTakingTopBannerActive}
                    onChange={() => {
                        handleIsTopBannerActiveSwitchOnClickEvent();
                    }}
                    onColor="#c2eddd"
                    onHandleColor="#34C38F"
                    width={50}
                    height={20}
                />
            </div>
            <div className="d-flex flex-row justify-content-between align-items-center">
                {t("Show selected category title")}

                <ReactSwitch
                    className="ksk_chk_expertTakeOrderFeaturesCategory"
                    uncheckedIcon={false}
                    checkedIcon={false}
                    handleDiameter={26}
                    offColor="#f7b4b8"
                    offHandleColor="#E30613"
                    checked={isOrderTakingSelectedCategoryTitleActive}
                    onChange={() => {
                        setIsOrderTakingSelectedCategoryTitleActive(
                            !isOrderTakingSelectedCategoryTitleActive
                        );
                        setIsPageSelectedCategoryTitleActive(
                            !isOrderTakingSelectedCategoryTitleActive,
                            "orderTaking"
                        );
                    }}
                    onColor="#c2eddd"
                    onHandleColor="#34C38F"
                    width={50}
                    height={20}
                />
            </div>
            <div className="d-flex flex-row justify-content-between align-items-center">
                {t("Show customer name")}

                <ReactSwitch
                    className="ksk_chk_expertTakeOrderFeaturesCustomer"
                    disabled={isCustomerAccountActive === false ? true : false}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    handleDiameter={26}
                    offColor="#f7b4b8"
                    offHandleColor="#E30613"
                    checked={isUserAccountOrderTakingActive}
                    onChange={handleIsClientActiveSwitchOnClickEvent}
                    onColor="#c2eddd"
                    onHandleColor="#34C38F"
                    width={50}
                    height={20}
                />
            </div>

            <hr className="w-100" />
            <span
                style={{
                    font: "normal normal 600 24px/40px Nunito Sans",
                }}
            >
                {t("Information et recherche")}
            </span>
            <div className="d-flex flex-row justify-content-between align-items-center">
                {t("Display filters ( tags )")}

                <ReactSwitch
                    className="ksk_chk_expertTakeOrderFeaturesFilter"
                    uncheckedIcon={false}
                    checkedIcon={false}
                    handleDiameter={26}
                    offColor="#f7b4b8"
                    offHandleColor="#E30613"
                    checked={isOrderTakingTagsActive}
                    onChange={() => {
                        setIsOrderTakingTagsActive(!isOrderTakingTagsActive);
                        setIsPageTagsActive(
                            !isOrderTakingTagsActive,
                            "orderTaking"
                        );
                    }}
                    onColor="#c2eddd"
                    onHandleColor="#34C38F"
                    width={50}
                    height={20}
                />
            </div>
            <div className="d-flex flex-row justify-content-between align-items-center">
                {t("Show 'i' information icon")}

                <ReactSwitch
                    className="ksk_chk_expertTakeOrderFeaturesInformationsProduct"
                    uncheckedIcon={false}
                    checkedIcon={false}
                    handleDiameter={26}
                    offColor="#f7b4b8"
                    offHandleColor="#E30613"
                    checked={isOrderTakingProductInformationActive}
                    onChange={() => {
                        setIsOrderTakingProductInformationActive(
                            !isOrderTakingProductInformationActive
                        );
                        setIsPageProductAccessIconActive(
                            !isOrderTakingProductInformationActive,
                            "orderTaking"
                        );
                    }}
                    onColor="#c2eddd"
                    onHandleColor="#34C38F"
                    width={50}
                    height={20}
                />
            </div>
            <div className="d-flex flex-row justify-content-between align-items-center">
                {t("Show 'Nutri-score' informations")}

                <ReactSwitch
                    className="ksk_icn_displayNutriScoreInformation"
                    uncheckedIcon={false}
                    checkedIcon={false}
                    handleDiameter={26}
                    offColor="#f7b4b8"
                    offHandleColor="#E30613"
                    checked={isNutriscoreActive}
                    onChange={() => {
                        setIsNutriscoreActive(!isNutriscoreActive);
                    }}
                    onColor="#c2eddd"
                    onHandleColor="#34C38F"
                    width={50}
                    height={20}
                />
            </div>
            <div className="d-flex flex-row justify-content-between align-items-center">
                {t("Show 'Calories' informations")}

                <ReactSwitch
                    className="ksk_icn_displayCaloriesInformation"
                    uncheckedIcon={false}
                    checkedIcon={false}
                    handleDiameter={26}
                    offColor="#f7b4b8"
                    offHandleColor="#E30613"
                    checked={isOrderTakingCaloryActive}
                    onChange={() => {
                        setIsOrderTakingCaloryActive(
                            !isOrderTakingCaloryActive
                        );
                        setPageOrderTaking({
                            ...(template.pages.ways[
                                "orderTaking"
                            ] as OrderTakingPageType).orderTakingProduct,
                            calorieIcon: {
                                ...(template.pages.ways[
                                    "orderTaking"
                                ] as OrderTakingPageType).orderTakingProduct
                                    .calorieIcon,
                                active: !isOrderTakingCaloryActive,
                            },
                        });
                    }}
                    onColor="#c2eddd"
                    onHandleColor="#34C38F"
                    width={50}
                    height={20}
                />
            </div>
            <hr className="w-100" />
            {posEditor[shopId] === POS_EDITOR_TCPOS ? (
                <>
                    <span
                        style={{
                            font: "normal normal 600 24px/40px Nunito Sans",
                        }}
                    >
                        {t("Module promotion")}
                    </span>
                    <div className="d-flex flex-row justify-content-between align-items-center">
                        {t("Activate the module")}

                        <ReactSwitch
                            className="ksk_chk_expertTakeOrderFeaturesCodePromotion"
                            uncheckedIcon={false}
                            checkedIcon={false}
                            handleDiameter={26}
                            offColor="#f7b4b8"
                            offHandleColor="#E30613"
                            checked={isOrderTakingPromoCodeActive}
                            onChange={() => {
                                setIsOrderTakingPromoCodeActive(
                                    !isOrderTakingPromoCodeActive
                                );
                                setIsPagePromoCodeActive(
                                    !isOrderTakingPromoCodeActive
                                );
                            }}
                            onColor="#c2eddd"
                            onHandleColor="#34C38F"
                            width={50}
                            height={20}
                            disabled={
                                ((template.pages.subSteps[
                                    "customerBenefit"
                                ] as any).options as CustomerBenefitOptionsType)
                                    .paymentWithLoyaltyBalance === true ||
                                isCustomerAccountActive === false
                                    ? true
                                    : false
                            }
                        />
                    </div>
                    <hr className="w-100" />
                </>
            ) : null}
            <span
                style={{
                    font: "normal normal 600 24px/40px Nunito Sans",
                }}
            >
                {t("Article comment")}
            </span>

            <div className="d-flex flex-row justify-content-between align-items-center">
                {t("Activate the module")}

                <ReactSwitch
                    className="ksk_chk_expertTakeOrderCmmentItemActivateModule"
                    uncheckedIcon={false}
                    checkedIcon={false}
                    handleDiameter={26}
                    offColor="#f7b4b8"
                    offHandleColor="#E30613"
                    checked={isCommentArticleActive}
                    onChange={() => {
                        handleIsCommentArticleActiveSwitchOnClickEvent();
                    }}
                    onColor="#c2eddd"
                    onHandleColor="#34C38F"
                    width={50}
                    height={20}
                />
            </div>
            <div className="d-flex flex-row justify-content-between align-items-center">
                {t("Enable free comment")}

                <ReactSwitch
                    className="ksk_chk_expertTakeOrderCommentItemActivateFreeComment"
                    uncheckedIcon={false}
                    checkedIcon={false}
                    handleDiameter={26}
                    offColor="#f7b4b8"
                    offHandleColor="#E30613"
                    checked={isFreeCommentActive}
                    disabled={
                        !isCommentArticleActive || !isCommentRecoveryActive
                    }
                    onChange={() => {
                        setIsFreeCommentActive(!isFreeCommentActive);
                        setIsPageFreeCommentActive(!isFreeCommentActive);
                    }}
                    onColor="#c2eddd"
                    onHandleColor="#34C38F"
                    width={50}
                    height={20}
                />
            </div>
            {/* <div className="d-flex flex-row justify-content-between align-items-center">

                  {t("Enable comment recovery")}

                    <ReactSwitch
                        className="ksk_chk_expertTakeOrderCommentItemRecoveryComment"
                        uncheckedIcon={false}
                        checkedIcon={false}
                        handleDiameter={26}
                        offColor="#f7b4b8"
                        offHandleColor="#E30613"
                        checked={isCommentRecoveryActive}
                        disabled={
                            !isCommentArticleActive || !isFreeCommentActive
                        }
                        onChange={() => {
                            setIsCommentRecoveryActive(
                                !isCommentRecoveryActive
                            );
                            setIsPageCommentRecoveryActive(
                                !isCommentRecoveryActive
                            );
                        }}
                        onColor="#c2eddd"
                        onHandleColor="#34C38F"
                        width={50}
                        height={20}
                    />
        </div> */}
        </div>
    );
}
