import { HeartIcon } from "@aureskonnect/react-ui";
import React from "react";
import { useSnapshot } from "valtio";
import { Tooltip } from "@mui/material";

import { setSalesSupportFavored, store } from "../store";
import { useTranslation } from "react-i18next";
import ErrorToast from "@components/Common/ErrorTost";
import { checkIfHasFavorite } from "@helpers/general";

type HeartIconComponentType = {
    element: any;
};

export function HeartIconComponent({ element }: HeartIconComponentType) {
    const { salesSupportFavored, activeSaleSupports } = useSnapshot(store);
    const { t } = useTranslation();

    const { Id } = element.selectedRow;

    const favorite = salesSupportFavored.find(
        (el: { id: string; status: boolean }) => el.id === Id
    );
    const isFavorite = favorite?.status || false;

    const activeSupport = activeSaleSupports.find(
        (el: { Id: string; state: boolean }) => el.Id === Id
    );
    const isActive = activeSupport?.state || false;

    return (
        <React.Fragment>
            <Tooltip
                title={
                    salesSupportFavored.find(
                        (el: any) => el.id === element.selectedRow.Id
                    ) !== undefined &&
                    salesSupportFavored.find(
                        (el: any) => el.id === element.selectedRow.Id
                    ).status
                        ? t("Disable favorite state")
                        : t("Enable favorite state")
                }
            >
                <div>
                    <HeartIcon
                        onClick={() => {
                            if (isActive) {
                                if (!isFavorite) {
                                    setSalesSupportFavored({
                                        id: Id,
                                        status: true,
                                    });
                                }
                            } else {
                                ErrorToast(
                                    t(
                                        "Please note, you cannot activate the favorite state for a disabled sale support"
                                    )
                                );
                            }
                        }}
                        height={25}
                        width={25}
                        name="heart"
                        fill={
                            salesSupportFavored.find(
                                (el: any) => el.id === element.selectedRow.Id
                            ) !== undefined &&
                            salesSupportFavored.find(
                                (el: any) => el.id === element.selectedRow.Id
                            ).status
                                ? "red"
                                : "black"
                        }
                        className="cmn_icn_AFlike"
                    />
                </div>
            </Tooltip>
        </React.Fragment>
    );
}
