import React from "react";
import { useTranslation } from "react-i18next";
import { ToastContainer } from "react-toastify";
import { StyledBreadcrumb } from "@aureskonnect/react-ui";
import { useSnapshot } from "valtio";
import { useLocation } from "react-router-dom";

import { store } from "../../../components/VerticalLayout/store";
import { setModify } from "./store";

import { MainColorTabsWrapper } from "@components/Common/MainColorTabsWrapper";
import { getPathAuthorization } from "@components/VerticalLayout";
import MessageInfo from "@components/Common/MessageInfo";
import PageTitle from "@components/Common/PageTitle";
import { Languages } from "./Languages";
import { LanguagesShop } from "./LanguagesShop";
import { StyledTabs } from "./StyledTabs";

import "@assets/swal.css";
import "@assets/theme.css";
import "react-toastify/dist/ReactToastify.css";
import "./index.css";

function LocalTabsLanguage() {
    const location = useLocation();

    setModify(getPathAuthorization(location.pathname).Modifie);
    const { t } = useTranslation();
    const { oneShop } = useSnapshot(store);
    const [limit, setLimit] = React.useState<number>(1);

    const [
        isArchivedLanguagesClicked,
        setIsArchivedLanguagesClicked,
    ] = React.useState(false);
    const tab1 = (
        <Languages
            setIsArchivedLanguagesClicked={setIsArchivedLanguagesClicked}
            isArchivedLanguagesClicked={isArchivedLanguagesClicked}
            setLimit={setLimit}
        />
    );
    const tab2 = (
        <StyledTabs
            tabs={[
                {
                    content: (
                        <Languages
                            setIsArchivedLanguagesClicked={
                                setIsArchivedLanguagesClicked
                            }
                            isArchivedLanguagesClicked={
                                isArchivedLanguagesClicked
                            }
                            setLimit={setLimit}
                        />
                    ),
                    disabled: false,
                    title: (
                        <div
                            className="d-flex justify-content-between text-dark cmn_btn_franchise"
                            style={{
                                gap: "10px",
                                font: "normal normal 600 16px/21px Segoe UI",
                            }}
                        >
                            {t("Language")}
                        </div>
                    ),
                },
                {
                    content: (
                        <LanguagesShop
                            setIsArchivedLanguagesClicked={
                                setIsArchivedLanguagesClicked
                            }
                            isArchivedLanguagesClicked={
                                isArchivedLanguagesClicked
                            }
                            setLimit={setLimit}
                        />
                    ),
                    title: (
                        <div
                            className="d-flex justify-content-between text-dark border-0 cmn_btn_boutique"
                            style={{
                                gap: "10px",
                                font: "normal normal 600 16px/21px Segoe UI",
                            }}
                        >
                            {t("Shop language")}
                        </div>
                    ),
                },
            ]}
        />
    );

    return (
        <React.Fragment>
            <ToastContainer limit={limit} />
            <PageTitle title={t("General settings")} />
            <div className="page-content__clz">
                <div className="lan_txt_language">
                    <StyledBreadcrumb
                        items={[
                            {
                                item: "Settings".toUpperCase(),
                                link: `${process.env.REACT_APP_PORTAIL_URL}`,
                            },
                            {
                                item:
                                    localStorage
                                        .getItem("selectedAccount")
                                        ?.toUpperCase() || "",
                                link: oneShop
                                    ? "/setting/customer-account"
                                    : "/compte",
                            },
                            {
                                item: t("General settings").toUpperCase(),
                                link: "#",
                            },
                            {
                                item: !isArchivedLanguagesClicked
                                    ? t("Language").toUpperCase()
                                    : t("Archived languages").toUpperCase(),
                                link: "#",
                            },
                        ]}
                    />
                </div>

                <MessageInfo
                    message={t(
                        "The languages used are configured in this section."
                    )}
                />

                <MainColorTabsWrapper>
                    {oneShop ? tab1 : tab2}
                </MainColorTabsWrapper>
            </div>
        </React.Fragment>
    );
}
export const TabsLanguage = React.memo(LocalTabsLanguage);
