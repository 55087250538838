import { t } from "i18next";
import { setArchivedFidelityFranchise, setDataArchive, setDataFranchiseArchive, setFidelityData, setFidelityFranchise, setIdsLocalShop, setLocalShop, setNumberActive, setNumberArchived, setSelectedFidelity } from "../store";

export function NumberActiveAndArchive(dataTable: any) {
    if (
        dataTable.othersData !== undefined &&
        dataTable.othersData.activated !== undefined
    ) {
        setNumberActive(dataTable.othersData.activated);
        setNumberArchived(dataTable.othersData.archived);
    } else {
        setNumberActive(0);
        setNumberArchived(0);
    }
}
export function Selected(selectedRows: any, dataTable: any) {
    let localShop: string[] = [];
    let idsLocalShop: string[] = [];
    selectedRows.forEach((el: any) => {
        if (el[t("Shop")] !== "" && el[t("Shop")] !== undefined)
            localShop.push(el.shopId);

        idsLocalShop.push(el.Id);
    });
    setIdsLocalShop(idsLocalShop);
    setLocalShop(localShop);
    let array: any = [];

    Object.values(selectedRows).forEach((element: any) => {
        array.push({ [element.shopId]: element.Id });
    });

    setSelectedFidelity(array);
    setFidelityData(dataTable);
    setArchivedFidelityFranchise(dataTable);
    setFidelityFranchise(dataTable);
    setDataArchive(
        dataTable?.othersData?.allData !== undefined
            ? dataTable?.othersData?.allData.othersData.listArchived
            : []
    );
    setDataFranchiseArchive(
        dataTable?.othersData?.allData !== undefined
            ? dataTable?.othersData?.allData.othersData.listArchived
            : []
    );

}