import React from "react";
import { useNavigate } from "react-router-dom";
import { StyledButton } from "@aureskonnect/react-ui";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";

import {
    setFidelityType,
    setMultiShopSwitch,
    setNumberClick,
    setOneShopSwitch,
    setPosEditorType,
} from "@components/VerticalLayout/store";
import { store } from "@store";

import "./index.css";
import { getFidelityAndEditorPos } from "@helpers/general";

export function CustomIconsComponent({ e }: any) {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { mainColor } = useSnapshot(store);
    const uuidUser = localStorage.getItem("uuidUser");

    function setShop(
        franchiseId: string,
        shopId: string,
        pays: string,
        shopName: string
    ) {
        setOneShopSwitch(franchiseId, shopId, pays, shopName);

        navigate("/setting/customer-account");
    }
    function setFranchise(franchiseId: string, shopName: string) {
        setMultiShopSwitch(franchiseId, shopName);

        navigate("/setting/customer-account");
    }

    return (
        <React.Fragment>
            {["Franchise", "franchise", "Boutique", "boutique"].includes(
                e.selectedRow.Nature
            ) ||
            ["Franchise", "franchise", "Boutique", "boutique"].includes(
                e.selectedRow["Account type"]
            ) ||
            ["Franchise", "franchise", "Boutique", "boutique"].includes(
                e.selectedRow.Kind
            ) ? (
                <StyledButton
                    outline
                    rounded
                    variant={mainColor}
                    onClick={async () => {
                        setNumberClick(0);
                        let franchiseId =
                            e.selectedRow.Kind === "Franchise"
                                ? e.selectedRow.uid
                                : e.selectedRow.uuidFranchise;
                        let shopId =
                            e.selectedRow.Kind === "Franchise"
                                ? "0"
                                : e.selectedRow.uid;
                        const getItemsDisableUrl: Response = await fetch(
                            `${process.env.REACT_APP_API_V2_URL}/settings/consomation/items?franchiseId=${franchiseId}&shopId=${shopId}`,
                            {
                                method: "GET",
                            }
                        );
                        const itemsDisable = await getItemsDisableUrl.json();

                        localStorage.setItem(
                            "itemsDisable",
                            JSON.stringify(itemsDisable.data)
                        );

                        await getFidelityAndEditorPos(
                            franchiseId,
                            uuidUser as string
                        );

                        if (
                            ["boutique", "Boutique"].includes(
                                e.selectedRow["Account type"]
                            ) ||
                            ["boutique", "Boutique"].includes(
                                e.selectedRow.Nature
                            ) ||
                            ["boutique", "Boutique"].includes(
                                e.selectedRow.Kind
                            )
                        ) {
                            localStorage.setItem(
                                "selectedAccount",
                                e.selectedRow[t("Commercial name")] ??
                                    e.selectedRow["Nom commercial"]
                            );

                            setShop(
                                e.selectedRow.Kind === "Franchise"
                                    ? e.selectedRow.uid
                                    : e.selectedRow.uuidFranchise,
                                e.selectedRow.uid,
                                e.selectedRow[t("Country")],
                                e.selectedRow[t("Commercial name")] ??
                                    e.selectedRow["Nom commercial"]
                                // e.selectedRow["Société"]LOCAL,
                            );
                        } else {
                            localStorage.setItem(
                                "selectedAccount",
                                e.selectedRow[t("Commercial name")] ??
                                    e.selectedRow["Nom commercial"]
                            );
                            setFranchise(
                                e.selectedRow.uid,
                                e.selectedRow[t("Commercial name")] ??
                                    e.selectedRow["Nom commercial"]
                            );
                        }
                    }}
                >
                    {t("To access")}
                </StyledButton>
            ) : (
                <div></div>
            )}
        </React.Fragment>
    );
}
