import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
    CrossIcon,
    StyledLabel,
    StyledTextInput,
    CheckboxIcon,
    StyledSelectInput,
} from "@aureskonnect/react-ui";
import { AvForm, AvGroup } from "availity-reactstrap-validation";
import { mutate } from "swr";
import { toast } from "react-toastify";
import classnames from "classnames";
import { useSnapshot } from "valtio";
import { detailedDiff, diff } from "deep-object-diff";

import {
    CustomCardBody,
    CustomCardFooter,
    CustomCardHeader,
} from "@components/Common/CustomCardStyled";

import { store } from "@components/VerticalLayout/store";
import {
    store as saleSupportStore,
    setFranchiseDataIsUpdated,
    setShopDataIsUpdated,
    setIsInlineErrorCleared,
    setIsActionsClicked,
    setActions,
    setIsEdited,
} from "../store";
import { setIsDataUpdated } from "@pages/Applications/Kiosk/store";
import { clearData } from "@components/Common/MultiCustomCreatableSelection/store";
import { clearMultiCustomSelectionData } from "@components/Common/MultiCustomSelect/store";

import { ConfirmationEditMessage } from "@components/Common/ConfirmationEditMessage";
import { ConfirmationModificationModal } from "./ConfirmationModificationModal";

import MultiCustomCreatableSelection from "@components/Common/MultiCustomCreatableSelection";

import { ConfirmationMessage } from "../SaleSupportShops/ConfirmationMessage";
import { ConfirmationModal } from "./ConfirmationModal";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";
import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";

import "@assets/swal.css";
import "@assets/theme.css";
import "react-toastify/dist/ReactToastify.css";
import "antd/dist/antd.css";
import AdvancedDisplayNameModal from "@pages/SettingGeneral/DisplayName/AdvancedDisplayNameModal";
import { languages } from "@constants/index";
import {
    CreatedataSaleSupport,
    advancedDisplayNameDefault,
    fechtingLanguageList,
    getTag,
    saleSupportFormType,
    translatedSaleSupport,
} from "./helper";

export default function SaleSupportForm({
    allDataShops,
    isAddSaleSupportClicked,
    setIsAddSaleSupportClicked,
    saleSupport,
    isAddNewSaleSupportButtonClicked,
    setSaleSupportData,
    userData,
    setLocalFilterActive,
    setLimit,
    setIsConsult,
    isConsult,
    dataState,
}: saleSupportFormType) {
    const { t } = useTranslation();
    const { userID, franchiseID, shopID, oneShop, operatorID } = useSnapshot(
        store
    );
    const {
        shopsSaleSupportData,
        saleSupportData,
        isInlineErrorCleared,
        listFranchiseArchived,
        archivedData,
        isInputUpdated,
        isActionsClicked,
        isEdited,
    } = useSnapshot(saleSupportStore);
    const shopId = oneShop ? shopID : "0";
    const [isNameExist, setIsNameExist] = React.useState<boolean>(false);
    const [displayName, setDisplayName] = React.useState<string>("");
    const [saleSupportLabel, setSaleSupportLabel] = React.useState<string>("");
    const [title, setTitle] = React.useState<string>("");
    const [identifier, setIdentifier] = React.useState<string>("");
    const [inputSaleSupportLabel, setInputSaleSupportLabel] = React.useState<
        boolean
    >(false);
    const [inputDisplayName, setInputDisplayName] = React.useState<boolean>(
        false
    );
    const [isModalOpened, setIsModalOpened] = React.useState<boolean>(false);
    const i18nextLng = localStorage.getItem("i18nextLng") || "fr";
    const [tags, setTags] = React.useState<any[]>([]);
    const [generalSalesSupports, setGeneralSalesSupports] = React.useState<
        any[]
    >([]);
    const [tagsData, setTagsData] = React.useState<any>([]);
    const [isSendingTag, setIsSendingTag] = React.useState<boolean>(false);

    const [isMessageModalOpened, setIsMessageModalOpened] = React.useState<
        boolean
    >(false);
    const [idsAssociated, setIdsAssociated] = React.useState<any>([]);
    const [existingSaleSupport, setExistingSaleSupport] = React.useState<any>(
        {}
    );
    const [
        isMessageValidateClicked,
        setIsMessageValidateClicked,
    ] = React.useState<boolean>(false);
    const [
        isSaleSupportAssociatedModified,
        setIsSaleSupportAssociatedModified,
    ] = React.useState<boolean>(false);
    const [
        isArchivedFranchiseModalOpened,
        setIsArchivedFranchiseModalOpened,
    ] = React.useState<boolean>(false);
    const [
        isValidateButtonDisabled,
        setIsValidateButtonDisabled,
    ] = React.useState<boolean>(false);
    const [
        isAdvancedDisplayNameModalOpen,
        setIsAdvancedDisplayNameModalOpen,
    ] = React.useState(false);
    const [advancedDisplayName, setAdvancedDisplayName] = React.useState<any>(
        advancedDisplayNameDefault
    );
    const [isArchived, setIsArchived] = React.useState<boolean>(false);
    const [language, setLanguage] = React.useState<string[]>([]);
    const selectedLanguage: any = languages.find(
        (el: any) => el.value === i18nextLng
    );

    const uuidUser = localStorage.getItem("uuidUser");

    const addSaleSupport = React.useCallback(async () => {
        toast.dismiss();
        setLimit(1);
        let id = generalSalesSupports.find(
            (elt: any) => elt.label === saleSupportLabel
        ).value;
        let dataSaleSupport = CreatedataSaleSupport(
            oneShop,
            isMessageValidateClicked,
            allDataShops,
            userID,
            franchiseID,
            operatorID,
            uuidUser,
            id,
            displayName,
            tagsData,
            saleSupportLabel,
            advancedDisplayName,
            shopId,
            saleSupportData
        );
        const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/general/saleSupport`;
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(dataSaleSupport),
        };

        try {
            mutate(
                apiUrlAdd,
                await fetch(apiUrlAdd, requestOptions)
                    .then((response) => response.json())
                    .then(async (data) => {
                        if (data.error) {
                            throw Error("error");
                        }
                        toast.success(
                            `${t("Sale support is successfully added")!}`,
                            {
                                position: toast.POSITION.TOP_CENTER,
                                theme: "colored",
                                icon: (
                                    <CheckboxIcon
                                        height={25}
                                        width={25}
                                        fill="white"
                                    />
                                ),
                            }
                        );
                        setIsSendingTag(true);
                        setIsDataUpdated!(true);
                        setFranchiseDataIsUpdated(true);
                        setShopDataIsUpdated(true);
                        setIsEdited(false);
                        setLocalFilterActive(false);
                        setTagsData([]);
                        setDisplayName("");
                        setSaleSupportLabel("");
                        clearData();
                        clearMultiCustomSelectionData();
                        setSaleSupportData([]);
                        setAdvancedDisplayName(advancedDisplayNameDefault);
                    })
            );
        } catch (e) {
            toast.error(`${t("There's an error")!}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        oneShop,
        isMessageValidateClicked,
        userID,
        shopId,
        franchiseID,
        identifier,
        displayName,
        tagsData,
        saleSupportLabel,
        t,
        setIsEdited,
        setLocalFilterActive,
        setSaleSupportData,
        setLimit,
    ]);

    async function editSaleSupport() {
        setLimit(1);

        let idsAssociated: any = [];
        let idsShopsAssociated: any = [];
        shopsSaleSupportData.forEach((el: any) => {
            el.subRows.forEach((element: any) => {
                idsAssociated.push(element.Id);
                idsShopsAssociated.push(element.shopId);
            });
        });
        setIdsAssociated([...new Set(idsShopsAssociated)]);

        let dataSaleSupport = [
            {
                userId: userID,
                shopId: [shopId],
                franchiseId: franchiseID,
                operatorId: operatorID,
                uuidUser: uuidUser,
                data: {
                    [identifier]: {
                        id: identifier,
                        displayName: displayName,
                        tag: tagsData.join(","),
                        isArchived: saleSupport.isArchived,
                        state: saleSupport.state,
                        shopId: shopId,
                        isActive:
                            Object.keys(dataState).length === 0
                                ? saleSupport.isActive
                                : saleSupport.Id === dataState.id
                                ? dataState.state
                                : saleSupport.isActive,
                        support_de_vente: saleSupportLabel,
                        isFavorite: saleSupport.isFavorite,
                        advancedDisplayName: advancedDisplayName,
                    },
                },
            },
        ];
        const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/general/saleSupport`;
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(dataSaleSupport),
        };

        try {
            mutate(
                apiUrlAdd,

                await fetch(apiUrlAdd, requestOptions)
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.error) {
                            throw Error("error");
                        }
                        toast.success(
                            userData.shopId === "0" &&
                                idsAssociated.includes(identifier)
                                ? `${t(
                                      "The sale support of franchise was successfully changed"
                                  )!}`
                                : `${t(
                                      "The sale support is changed successfully"
                                  )!}`,
                            {
                                position: toast.POSITION.TOP_CENTER,
                                theme: "colored",
                                icon: (
                                    <CheckboxIcon
                                        height={25}
                                        width={25}
                                        fill="white"
                                    />
                                ),
                            }
                        );
                    })
            );

            if (userData.shopId === "0" && idsAssociated.includes(identifier)) {
                setIsSaleSupportAssociatedModified(true);
                setFranchiseDataIsUpdated(true);
            } else {
                setIsSendingTag(true);
                setIsDataUpdated!(true);
                setFranchiseDataIsUpdated(true);
                setShopDataIsUpdated(true);
                setLocalFilterActive(false);
                setIsEdited(false);
                setDisplayName("");
                setTagsData([]);
                setSaleSupportLabel("");
                clearData();
                clearMultiCustomSelectionData();
                setSaleSupportData([]);
                setIsAddSaleSupportClicked!(!isAddSaleSupportClicked);
                setIsEdited(false);
            }
        } catch (e) {
            toast.error(`${t("There's an error")!}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    }

    async function getGeneralSaleSupport() {
        const newList: any[] = [];
        await fetch(
            `${process.env.REACT_APP_API_V2_URL}/settings/general/generalList?type=saleSupport&userId=${userID}&shopId=${shopID}&franchiseId=${franchiseID}`,

            {
                method: "GET",
                headers: { "Accept-Language": i18nextLng },
            }
        )
            .then((res) => res.json())
            .then(
                (result) => {
                    for (let element of result.data) {
                        newList.push({
                            value: element.Id,
                            label: element.Designation,
                        });
                    }
                    setGeneralSalesSupports(newList);
                },
                (error) => {
                    console.log(error);
                }
            );
    }

    const formatCreateLabelTag = (inputValue: any) => (
        <span>
            {inputValue} ({t("New tag")})
        </span>
    );

    function handleValidateButtonOnClickEvent() {
        setLimit(1);
        setIsValidateButtonDisabled(true);
        if (
            saleSupport?.Id !== undefined &&
            saleSupport.advancedDisplayName === advancedDisplayName &&
            Object.keys(
                detailedDiff(
                    saleSupport.Tag.split(",").filter((x: any) => x !== ""),
                    tagsData
                ).updated
            ).length === 0 &&
            Object.keys(
                detailedDiff(
                    saleSupport.Tag.split(",").filter((x: any) => x !== ""),
                    tagsData
                ).added
            ).length === 0 &&
            Object.keys(
                detailedDiff(
                    saleSupport.Tag.split(",").filter((x: any) => x !== ""),
                    tagsData
                ).deleted
            ).length === 0 &&
            saleSupport[t("Sale support")] === saleSupportLabel
        ) {
            setIsEdited(false);
            setIsModalOpened(true);
        } else {
            if (saleSupportLabel === "") {
                setInputSaleSupportLabel(true);
            }

            if (advancedDisplayName.languages[i18nextLng] === "") {
                setInputDisplayName(true);
            }

            if (isNameExist) {
                setIsValidateButtonDisabled(false);
            }

            if (saleSupport.Id) {
                let sameSaleSupport: any = allDataShops.find(
                    (el: any) => el[t("Sale support")] === saleSupportLabel
                );

                let isTagNotModified: any =
                    sameSaleSupport !== undefined &&
                    sameSaleSupport?.Tag === tagsData.join(",");

                if (
                    saleSupport.Id !== undefined &&
                    saleSupportLabel !== "" &&
                    advancedDisplayName.languages[i18nextLng].trim() !== "" &&
                    !isNameExist
                ) {
                    if (
                        isEdited === false &&
                        isTagNotModified &&
                        Object.keys(
                            diff(
                                saleSupport.advancedDisplayName,
                                advancedDisplayName
                            )
                        ).length === 0
                    ) {
                        setIsModalOpened(!isModalOpened);
                    } else {
                        editSaleSupport();
                    }
                }
            } else if (
                saleSupportLabel !== "" &&
                advancedDisplayName.languages[i18nextLng].trim() !== "" &&
                !isNameExist
            ) {
                let isSaleSupportExistArchivedFranchise = listFranchiseArchived.find(
                    (el: any) => el[t("Sale support")] === saleSupportLabel
                );
                let isSaleSupportExist: boolean = false;

                if (allDataShops !== undefined) {
                    isSaleSupportExist =
                        allDataShops.find(
                            (el: any) =>
                                el[t("Sale support")] === saleSupportLabel
                        ) !== undefined;
                }

                if (isSaleSupportExistArchivedFranchise !== undefined) {
                    setIsMessageModalOpened(true);
                    setIsArchived(true);
                } else if (allDataShops !== undefined && isSaleSupportExist) {
                    setIdentifier(
                        // eslint-disable-next-line
                        allDataShops.find((el: any) => {
                            if (el[t("Sale support")] === saleSupportLabel)
                                return el.Id;
                        }).Id
                    );
                    setIsMessageModalOpened(true);
                    setIsArchived(false);
                } else {
                    addSaleSupport();
                }
            }
        }
        setTimeout(() => {
            setIsValidateButtonDisabled(false);
        }, 500);
    }

    const checkIfSaleSupportExist = React.useCallback(() => {
        let allSaleSupport = [...saleSupportData, ...archivedData];
        return allSaleSupport.some(
            (element) => element[t("Sale support")] === saleSupportLabel
        );
    }, [archivedData, saleSupportData, saleSupportLabel, t]);

    React.useEffect(() => {
        fechtingLanguageList(setLanguage, userID, shopId, franchiseID);
        // eslint-disable-next-line
    }, [isAdvancedDisplayNameModalOpen]);

    useEffect(() => {
        if (saleSupport.Id) {
            setIdentifier(saleSupport.Id);
            setTitle(
                t(isConsult ? "View a sale support" : "Modify a sale support")
            );
            setDisplayName(saleSupport[t("Display name")]);
            setTagsData(saleSupport.Tag.split(","));
            setSaleSupportLabel(saleSupport[t("Sale support")]);
            setAdvancedDisplayName(saleSupport.advancedDisplayName);
        } else {
            setDisplayName("");
            setTitle(t("Add a sale support"));
            setSaleSupportLabel("");
            setIdentifier("");
            setTagsData([]);
            setAdvancedDisplayName(advancedDisplayNameDefault);
        }
        // eslint-disable-next-line
    }, [saleSupport, t, isAddNewSaleSupportButtonClicked]);

    useEffect(() => {
        getGeneralSaleSupport();
        getTag(userID, shopID, franchiseID, setTags);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [saleSupport]);

    useEffect(() => {
        if (isInlineErrorCleared === true) {
            setInputDisplayName(false);
            setIsInlineErrorCleared(false);
            clearData();
            clearMultiCustomSelectionData();
            setTagsData([]);
            setGeneralSalesSupports([]);
        }
    }, [isInlineErrorCleared]);

    useEffect(() => {
        clearData();
        clearMultiCustomSelectionData();
    }, [isAddSaleSupportClicked, saleSupport.Id]);

    useEffect(() => {
        setInputDisplayName(false);
        setIsInlineErrorCleared(false);
    }, [isInputUpdated]);
    useEffect(() => {
        if (advancedDisplayName.languages[i18nextLng] !== "") {
            setInputDisplayName(false);
        }
        // eslint-disable-next-line
    }, [advancedDisplayName, i18nextLng]);

    useEffect(() => {
        let isSaleSupportExist = checkIfSaleSupportExist();
        if (
            isSaleSupportExist &&
            (saleSupport.Id === undefined ||
                (saleSupport.Id !== undefined &&
                    saleSupport[t("Sale support")] !== saleSupportLabel))
        ) {
            setIsNameExist(true);
        } else {
            setIsNameExist(false);
        }
    }, [
        saleSupportLabel,
        t,
        displayName,
        checkIfSaleSupportExist,
        saleSupport,
    ]);

    React.useEffect(() => {
        let allSaleShop: any = [];
        if (shopsSaleSupportData !== undefined) {
            shopsSaleSupportData.forEach((el: any) => {
                if (el.subRows !== undefined)
                    el.subRows.forEach((element: any) => {
                        allSaleShop.push(element);
                    });
            });
        }

        setExistingSaleSupport(
            allSaleShop.find((el: any) => el.Id === identifier)
        );
    }, [identifier, shopsSaleSupportData]);

    React.useEffect(() => {
        if (isMessageValidateClicked === true) {
            addSaleSupport();
            setIsMessageModalOpened(false);
        }
        setIsMessageValidateClicked(false);
    }, [
        allDataShops,
        userData,
        isMessageValidateClicked,
        addSaleSupport,
        t,
        saleSupportLabel,
        tagsData,
        saleSupport,
    ]);

    return (
        <React.Fragment>
            <AvForm className="card">
                <CustomCardHeader
                    className="d-flex align-items-center justify-content-between"
                    style={{ maxHeight: "76px" }}
                >
                    <h5 className="pt-3 sdv_txt_FRtitleFormSalesSupport">
                        {title}
                    </h5>
                    <CrossIcon
                        className="icon-close-notification__clz sdv_icn_FRcloseForm"
                        height={13}
                        width={13}
                        onClick={() => {
                            setIsConsult(false);
                            if (isEdited === true) {
                                setIsActionsClicked(!isActionsClicked);
                                setActions(() => {
                                    setIsEdited(false);
                                    setSaleSupportData([]);
                                    setIsAddSaleSupportClicked!(
                                        !isAddSaleSupportClicked
                                    );
                                    setIsEdited(false);
                                    clearData();
                                    clearMultiCustomSelectionData();
                                });
                            } else {
                                setSaleSupportData([]);
                                setIsAddSaleSupportClicked!(
                                    !isAddSaleSupportClicked
                                );
                                setIsEdited(false);
                                clearData();
                                clearMultiCustomSelectionData();
                            }
                        }}
                    />
                </CustomCardHeader>
                <CustomCardBody
                    className={`pl-3 pr-5 `}
                    style={{
                        maxHeight: "470px",
                        overflowY: " auto",
                    }}
                >
                    {" "}
                    <AvGroup className={`${isConsult ? "pe-none__clz" : ""}`}>
                        <StyledLabel
                            htmlFor="text"
                            className="required__clz mt-3"
                        >
                            {t("Sales support")}
                        </StyledLabel>
                        <StyledSelectInput
                            isClearable
                            isDisabled={
                                saleSupport.Id !== undefined ? true : false
                            }
                            options={generalSalesSupports}
                            autoComplete="off"
                            placeholder={`${t("Select")}…`}
                            value={
                                saleSupportLabel === ""
                                    ? null
                                    : {
                                          label: saleSupportLabel,
                                          value: saleSupportLabel,
                                      }
                            }
                            onChange={(e: any) => {
                                if (e === null && displayName === "") {
                                    setAdvancedDisplayName(
                                        advancedDisplayNameDefault
                                    );
                                    setSaleSupportLabel("");
                                } else if (e !== null) {
                                    setAdvancedDisplayName({
                                        ...advancedDisplayName,
                                        languages:
                                            translatedSaleSupport[e.label],
                                    });
                                    setSaleSupportLabel(e.label);
                                }
                                setIsEdited(true);
                                setInputSaleSupportLabel(false);
                                setDisplayName(e !== null ? e.label : "");
                                setInputDisplayName(false);
                            }}
                            className={classnames("sdv_inp_FRsalesSupport", {
                                invalid__clz:
                                    inputSaleSupportLabel || isNameExist,
                                "not-allowed-input__clz":
                                    saleSupport.id !== undefined &&
                                    generalSalesSupports.includes(
                                        saleSupport[t("Sale support")]
                                    ),
                            })}
                            noOptionsMessage={() => t("No options")}
                            name={t("Sales support")}
                            autoFocus
                        />
                        {inputSaleSupportLabel ? (
                            <div
                                style={{
                                    width: "100%",
                                    marginTop: "0.25rem",
                                    fontSize: "80%",
                                    color: "#f46a6a",
                                }}
                            >
                                {t("Please select a sales support")}
                            </div>
                        ) : null}
                        {isNameExist ? (
                            <div
                                style={{
                                    width: "100%",
                                    marginTop: "0.25rem",
                                    fontSize: "80%",
                                    color: "#f46a6a",
                                }}
                            >
                                {t("The sale support is already exists")!}
                            </div>
                        ) : null}
                    </AvGroup>
                    <AvGroup>
                        <div className="d-flex justify-content-between mb-1">
                            <StyledLabel
                                htmlFor="text"
                                className="required__clz mt-3"
                            >
                                {t("Display name")}
                            </StyledLabel>
                            <span
                                style={{
                                    color: "blue",
                                    cursor: "pointer",
                                    border: "transparent",
                                    backgroundColor: "transparent",
                                    textAlign: "center",
                                    textDecoration: "underline",
                                    font:
                                        "normal normal 600 16px/21px Segoe UI",
                                    marginTop: "15px",
                                }}
                                role="button"
                                tabIndex={0}
                                onClick={(): void => {
                                    setIsAdvancedDisplayNameModalOpen(
                                        !isAdvancedDisplayNameModalOpen
                                    );
                                }}
                                className={classnames(
                                    "cmn_btn_advencedDisplayName",

                                    {
                                        "not-allowed-icon__clz":
                                            advancedDisplayName.languages[
                                                i18nextLng
                                            ] === "" && saleSupportLabel === "",
                                    }
                                )}
                            >
                                <span>{t("Advanced")}</span>
                            </span>
                        </div>
                        <StyledTextInput
                            className={classnames("sdv_inp_FRdisplayName", {
                                "pe-none__clz": isConsult,
                                input__clz:
                                    inputDisplayName ||
                                    (advancedDisplayName.languages[
                                        i18nextLng
                                    ].trim() === "" &&
                                        advancedDisplayName.languages[
                                            i18nextLng
                                        ] !== ""),
                            })}
                            autoComplete="off"
                            name="DisplayName"
                            onChange={(e: any) => {
                                if (
                                    saleSupportLabel === "" &&
                                    e.target.value === ""
                                ) {
                                    setAdvancedDisplayName(advancedDisplayNameDefault);
                                } else {
                                    setAdvancedDisplayName({
                                        ...advancedDisplayName,
                                        languages: {
                                            ...advancedDisplayName.languages,
                                            [selectedLanguage.value]:
                                                e.target.value,
                                        },
                                    });
                                }
                                e !== null
                                    ? setDisplayName(e.target.value.trim())
                                    : setDisplayName("");
                                setIsEdited(true);
                                setInputDisplayName(false);
                            }}
                            value={
                                advancedDisplayName.languages[i18nextLng] ?? ""
                            }
                            placeholder={t("Write")}
                            type="text"
                        />
                        {inputDisplayName ||
                        (advancedDisplayName.languages[i18nextLng].trim() ===
                            "" &&
                            advancedDisplayName.languages[i18nextLng] !==
                                "") ? (
                            <div
                                style={{
                                    width: "100%",
                                    marginTop: "0.25rem",
                                    fontSize: "80%",
                                    color: "#f46a6a",
                                }}
                            >
                                {t("Please enter a display name")}
                            </div>
                        ) : null}
                    </AvGroup>
                    <AvGroup className={`${isConsult ? "pe-none__clz" : ""}`}>
                        <StyledLabel htmlFor="text" className="mt-3">
                            {t("Tag")}
                        </StyledLabel>
                        <MultiCustomCreatableSelection
                            onDelete={() => {
                                setIsEdited(true);
                            }}
                            isSendingTag={isSendingTag}
                            setResult={setTagsData}
                            result={tagsData}
                            data={tags}
                            onChange={(e: any) => {
                                setIsEdited(true);
                            }}
                            onCreateOption={(e: any) => {
                                setIsEdited(true);
                            }}
                            placeholder={`${t("Select")}…`}
                            value={t("Choose")}
                            name="tag"
                            noOptionsMessage={() => t("No options")}
                            formatCreateLabel={formatCreateLabelTag}
                            className="sdv_inp_FRtags"
                        />

                        <div className="d-flex flex-wrap"></div>
                    </AvGroup>
                </CustomCardBody>
                <CustomCardFooter className="pt-1 pb-2">
                    <CustomSecondaryColorButton
                        outline
                        rounded
                        className="mr-2 sdv_btn_FRcancel cmn_btn_cancel"
                        variant="light"
                        onClick={() => {
                            if (isEdited === true) {
                                setIsActionsClicked(!isActionsClicked);
                                setActions(() => {
                                    setIsEdited(false);
                                    setSaleSupportData([]);
                                    setIsAddSaleSupportClicked(
                                        !isAddSaleSupportClicked
                                    );
                                    clearData();
                                    clearMultiCustomSelectionData();
                                });
                            } else {
                                setSaleSupportData([]);
                                setIsAddSaleSupportClicked(
                                    !isAddSaleSupportClicked
                                );
                                clearData();
                                clearMultiCustomSelectionData();
                            }
                            setIsConsult(false);
                        }}
                    >
                        <span> {t("Cancel")}</span>
                    </CustomSecondaryColorButton>
                    <CustomMainColorButton
                        rounded
                        variant="primary"
                        disabled={isValidateButtonDisabled}
                        onClick={() => {
                            handleValidateButtonOnClickEvent();
                        }}
                        className={`sdv_btn_FRvalidate cmn_btn_validate ${classnames(
                            { "not-allowed-input-vr__clz": isConsult }
                        )}`}
                    >
                        {t("Validate")}
                    </CustomMainColorButton>
                </CustomCardFooter>
            </AvForm>
            <ConfirmationEditMessage
                setDataIsUpdated={setFranchiseDataIsUpdated}
                isModalOpened={isModalOpened}
                setIsModalOpened={setIsModalOpened}
                isAddClicked={isAddSaleSupportClicked}
                setIsAddClicked={setIsAddSaleSupportClicked}
                setEditedData={setSaleSupportData}
            />
            {isSaleSupportAssociatedModified && (
                <ConfirmationModificationModal
                    setSaleSupportData={setSaleSupportData}
                    data={{
                        [identifier]: {
                            id: identifier,
                            displayName:
                                advancedDisplayName.languages[i18nextLng],
                            shopId: shopId,
                            support_de_vente: saleSupportLabel,
                            tag: tagsData.join(","),
                            isActive: existingSaleSupport.isActive,
                            isArchived: false,
                            isFavorite: existingSaleSupport.isFavorite,
                            advancedDisplayName: advancedDisplayName,
                        },
                    }}
                    resetData={() => {
                        clearData();
                        clearMultiCustomSelectionData();
                        setTagsData([]);
                        setSaleSupportLabel("");
                    }}
                    isAddSaleSupportClicked={isAddSaleSupportClicked}
                    userData={userData}
                    setIsAddSaleSupportClicked={setIsAddSaleSupportClicked}
                    setIsEdited={setIsEdited}
                    isModalOpened={isSaleSupportAssociatedModified}
                    setIsModalOpened={setIsSaleSupportAssociatedModified}
                    idsAssociated={idsAssociated}
                />
            )}
            {isMessageModalOpened ? (
                <ConfirmationMessage
                    msg={
                        !isArchived
                            ? t(
                                  "The sale support already exists in the franchise. Do you want to associate it with this shop"
                              )
                            : t(
                                  "Attention! the sales support is already archived at the franchise level"
                              )
                    }
                    setIsModalOpened={setIsMessageModalOpened}
                    isModalOpened={isMessageModalOpened}
                    setIsValidateClicked={setIsMessageValidateClicked}
                    isArchived={isArchived}
                />
            ) : null}
            {isArchivedFranchiseModalOpened ? (
                <ConfirmationModal
                    isConfirmationModal={isArchivedFranchiseModalOpened}
                    setIsConfirmationModal={setIsArchivedFranchiseModalOpened}
                />
            ) : null}
            {isAdvancedDisplayNameModalOpen && (
                <AdvancedDisplayNameModal
                    isConsult={isConsult}
                    isAdvancedDisplayNameModalOpen={
                        isAdvancedDisplayNameModalOpen
                    }
                    setIsAdvancedDisplayNameModalOpen={
                        setIsAdvancedDisplayNameModalOpen
                    }
                    setAdvancedDisplayName={setAdvancedDisplayName}
                    displayName={advancedDisplayName}
                    language={language}
                    isActionsClicked={isActionsClicked}
                    setActions={setActions}
                    setIsActionsClicked={setIsActionsClicked}
                    setIsEdited={setIsEdited}
                />
            )}
        </React.Fragment>
    );
}
