import React from "react";
import { Card, Input } from "reactstrap";
import { useTranslation } from "react-i18next";
import { AvForm } from "availity-reactstrap-validation";
import { useSnapshot } from "valtio";
import {
    CheckIcon,
    PencilIcon,
    StyledIconButton,
    RefreshIcon,
} from "@aureskonnect/react-ui";
import { uid } from "react-uid";

import {
    generalConfigStore,
    setProjectFiles,
    setIsResetOneImageButtonClicked,
    setIsInformationModesEmpty,
    setConfigOptionsCustomerAccount,
    setIsCustomerAccountActive,
    setIsStateChanged,
    store,
} from "@store";

import { getImageContentById } from "@helpers/general";

import { Uploader } from "@components/Uploader";
import classnames from "classnames";

type PropsType = {
    item: ProjectMainContentItemType;
    index: number;
    setItems: React.Dispatch<
        React.SetStateAction<ProjectMainContentItemType[]>
    >;
    setConfig: (items: ProjectMainContentItemType[]) => void;
    hasTooltip: boolean;
};

export function CardWrapper({
    item,
    index,
    setItems,
    setConfig,
    hasTooltip = false,
}: PropsType): JSX.Element {
    const { t } = useTranslation();
    const { mainColor } = useSnapshot(store);
    const {
        activeSubTab,
        defaultLanguage,
        userLanguage,
        isResetOneImageButtonClicked,
        project,
        imageKey,
        itemsDisable,
    } = useSnapshot(generalConfigStore);

    const [cardImageKey, setCardImageKey] = React.useState<string>("");
    const [inputValue, setInputValue] = React.useState<string>(
        item.languages[defaultLanguage.name].name
    );

    const imageContent = getImageContentById(
        item.id,
        project.files,
        defaultLanguage.name
    );

    const imageSrc =
        imageContent?.includes("http") || imageContent?.includes("https")
            ? imageContent
            : `./images/${imageContent}`;

    function handleInputOnBlurEvent() {
        setItems(
            (
                prevState: ProjectMainContentItemType[]
            ): ProjectMainContentItemType[] => {
                let newState = [...prevState];

                newState = newState.map(
                    (item: ProjectMainContentItemType, itemIndex: number) => {
                        if (itemIndex === index) {
                            return {
                                ...item,
                                languages: {
                                    ...item.languages,
                                    [defaultLanguage.name]: {
                                        ...item.languages[defaultLanguage.name],
                                        name: inputValue,
                                    },
                                },
                            };
                        }

                        return { ...item };
                    }
                );
                setConfig(newState);
                return newState;
            }
        );
    }

    function handleCheckIconOnClickEvent(index: number) {
        setItems((prevState: ProjectMainContentItemType[]) => {
            let newState = [...prevState];

            const activeItemsCount = newState.filter(
                (item): boolean => item.active === true
            ).length;

            newState = newState.map(
                (item: ProjectMainContentItemType, itemIndex: number) => {
                    if (itemIndex === index) {
                        const INFORMATION_MODE_TAB_NUMBER = 4;
                        return {
                            ...item,
                            active:
                                activeSubTab === INFORMATION_MODE_TAB_NUMBER
                                    ? !item.active
                                    : activeItemsCount !== 1
                                    ? !item.active
                                    : true,
                        };
                    }
                    if (item.name === "Delivery" && item.active === true) {
                        setIsCustomerAccountActive(true);
                        setIsStateChanged(true);
                        setConfigOptionsCustomerAccount(true);
                    }

                    return { ...item };
                }
            );

            setConfig(newState);
            return newState;
        });
    }

    function handleResetImagesOnClickEvent(id: string) {
        const imagesArray = [...project.files];
        const image = imagesArray.find((element) => element.id === id);

        if (image !== undefined) {
            const imageIndex = imagesArray.indexOf(image);
            const localContent = image.content as ImageItemLanguagesType;

            imagesArray[imageIndex] = {
                ...image,
                content: {
                    ...localContent,
                    [defaultLanguage.name]: {
                        ...localContent[defaultLanguage.name],
                        path: localContent[defaultLanguage.name]
                            .defaultImage as string,
                    },
                } as ImageItemLanguagesType,
            };
        }
        setProjectFiles(imagesArray);
        setCardImageKey(uid(imagesArray));
    }

    React.useEffect(() => {
        const filteredInformationMode = (project.template.content
            .salesMethods as ProjectContentItemType).items.filter(
            (item1: any) =>
                item1.name !== "Retrait C&C" &&
                (item1.informationModes as InformationModesContentType).items
                    .length !== 0 &&
                item1.active === true
        );

        if (item.active === true && filteredInformationMode.length > 0) {
            setIsInformationModesEmpty(false);
        }
        if (item.active === false && filteredInformationMode.length === 0) {
            setIsInformationModesEmpty(true);
        }
    }, [
        item.active,
        item.name,
        project.template.content.informationModes,
        project.template.content.salesMethods,
    ]);

    React.useEffect(() => {
        setInputValue(item.languages[defaultLanguage.name].name);
    }, [defaultLanguage, item.languages]);

    const salesMethodsActive = (project.template.content
        .salesMethods as ProjectContentItemType).items.filter(
        (sale: any) =>
            sale.active === true &&
            sale.id !== "f25e7c96-b5d3-4f2e-acd0-1500258283c2"
    );
    return (
        <div
            className="d-flex flex-column justify-content-center cursor__clz"
            style={{
                gap: "10px",
                width: "min-content",
                borderBottom: "1px solid #707070",
                opacity: item.active === false ? "40%" : "",
            }}
        >
            <div
                className="d-flex align-items-center justify-content-start align-items-center text-muted cursor__clz"
                style={{ gap: "10px" }}
            >
                {/* {hasTooltip === true ? (
                    <div>
                        <div id="tooltip-title-card">
                            <HelpIcon height={25} width={25} />
                        </div>
                        <UncontrolledTooltip
                            placement="bottom"
                            target="tooltip-title-card"
                        >
                            {t("You can edit it")}
                        </UncontrolledTooltip>
                    </div>
                ) : null} */}
                {item.languages[userLanguage].defaultContent}
            </div>
            <Card
                className={classnames({
                    "disable-account-creation-items__clz":
                        itemsDisable.find(
                            (element: any) =>
                                element.id === item.id &&
                                element.shopId === `${project.shopId}`
                        ) !== undefined,
                })}
                style={{
                    borderBottom: item.active
                        ? `${mainColor} 10px solid`
                        : "unset",
                    width: "250px",
                    height: "220px",
                    borderRadius: "20px",
                }}
            >
                <div
                    style={{
                        display: "grid",
                        gridTemplateRows: "0.9fr auto",
                        height: "100%",
                    }}
                >
                    <div
                        style={{
                            display: "grid",
                            gridTemplateColumns: "0.2fr 0.6fr 0.2fr",
                            height: "100%",
                        }}
                    >
                        <CheckIcon
                            className="mt-1 ml-1 cmn_chk_selectButton"
                            height={45}
                            width={45}
                            style={{
                                fill: item.active ? mainColor : "#BCBCBC",
                                cursor: "pointer",
                            }}
                            onClick={() => {
                                if (
                                    salesMethodsActive.length === 1 &&
                                    salesMethodsActive[0].id === item.id
                                ) {
                                    return;
                                } else if (
                                    (salesMethodsActive.length === 1 &&
                                        salesMethodsActive[0].id !== item.id) ||
                                    salesMethodsActive.length > 1 ||
                                    item.id ===
                                        "f25e7c96-b5d3-4f2e-acd0-1500258283c2" ||
                                    (project.template.content
                                        .salesMethods as ProjectContentItemType).items.findIndex(
                                        (sale: any) => item.name === sale.name
                                    ) === -1 ||
                                    itemsDisable.find(
                                        (element: any) =>
                                            element.id === item.id &&
                                            element.shopId ===
                                                `${project.shopId}`
                                    ) === undefined
                                ) {
                                    handleCheckIconOnClickEvent(index);
                                }
                            }}
                        />
                        <div
                            className="d-flex justify-content-center align-items-end"
                            onClick={() => {
                                if (
                                    salesMethodsActive.length === 1 &&
                                    salesMethodsActive[0].id === item.id
                                ) {
                                    return;
                                } else if (
                                    (salesMethodsActive.length === 1 &&
                                        salesMethodsActive[0].id !== item.id) ||
                                    salesMethodsActive.length > 1 ||
                                    item.id ===
                                        "f25e7c96-b5d3-4f2e-acd0-1500258283c2" ||
                                    (project.template.content
                                        .salesMethods as ProjectContentItemType).items.findIndex(
                                        (sale: any) => item.name === sale.name
                                    ) === -1 ||
                                    itemsDisable.find(
                                        (element: any) =>
                                            element.id === item.id &&
                                            element.shopId ===
                                                `${project.shopId}`
                                    ) === undefined
                                ) {
                                    handleCheckIconOnClickEvent(index);
                                }
                            }}
                        >
                            <img
                                alt="img"
                                key={index}
                                src={imageSrc}
                                style={{
                                    width: "120px",
                                    height: "120px",
                                }}
                            />
                        </div>
                        <StyledIconButton
                            title={t("Reset to default image")}
                            disabled={item.active === false}
                            outline={true}
                            variant="danger"
                            className="m-0 p-0 cmn_icn_resetPhoto"
                            icon="RefreshIcon"
                            style={{ height: "45%" }}
                            onClick={() => {
                                handleResetImagesOnClickEvent(item.id);
                                setIsResetOneImageButtonClicked(true);
                            }}
                        >
                            <RefreshIcon height={20} width={20} />
                        </StyledIconButton>
                    </div>

                    <div
                        className="d-flex justify-content-center align-items-center"
                        style={{
                            gap: "10px",
                        }}
                    >
                        <div
                            className="d-flex justify-content-center shadow-sm align-items-center rounded border cursor__clz cmn_txt_optionOrder"
                            style={{
                                height: "40px",
                                width: "40px",
                            }}
                        >
                            <span
                                style={{
                                    fontSize: "15px",
                                }}
                            >
                                {index + 1}
                                <sup>
                                    {index + 1 === 1 ? t("First") : t("th")}
                                </sup>
                            </span>
                        </div>

                        <fieldset disabled={item.active === false}>
                            <div
                                className="d-flex justify-content-center shadow-sm align-items-center rounded border cmn_icn_uploadPhoto"
                                style={{
                                    height: "40px",
                                    width: "40px",
                                }}
                            >
                                <Uploader
                                    key={
                                        isResetOneImageButtonClicked === false
                                            ? imageKey
                                            : cardImageKey
                                    }
                                    id={item.id}
                                    activeLanguage={defaultLanguage.name}
                                />
                            </div>
                        </fieldset>
                    </div>
                </div>
            </Card>
            <AvForm
                className="d-flex justify-content-center align-items-center"
                style={{ gap: "5px" }}
            >
                {item.active === true ? (
                    <PencilIcon
                        height={20}
                        width={20}
                        className="cmn_icn_editIcon"
                    />
                ) : null}

                <Input
                    className="border-0 cmn_txt_nameOption"
                    id="card-title"
                    name="card-title"
                    type="text"
                    disabled={item.active === false}
                    style={{
                        backgroundColor: "transparent",
                        fontSize: "20px",
                        color: "#000000",
                    }}
                    value={inputValue}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setInputValue(event.target.value);
                    }}
                    onBlur={handleInputOnBlurEvent}
                />
            </AvForm>
        </div>
    );
}
