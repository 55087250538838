import React from "react";
import { useTranslation } from "react-i18next";
import { Card, Input } from "reactstrap";
import {
    CheckIcon,
    PencilIcon,
    RefreshIcon,
    StyledIconButton,
} from "@aureskonnect/react-ui";
import { AvForm } from "availity-reactstrap-validation";
import { useSnapshot } from "valtio";
import { uid } from "react-uid";

import {
    generalConfigStore,
    setAuthenticationModesOptions,
    setProjectFiles,
    setIsResetOneImageButtonClicked,
    store,
} from "@store";

import { getImageContentById } from "@helpers/general";
import { store as storeGlobal } from "@components/VerticalLayout/store";
import { Uploader } from "@components/Uploader";
import classnames from "classnames";
import { GODIA_LOYALTY } from "@constants/index";

type AuthenticationModesCardWrapperPropsType = {
    item: ProjectMainContentItemType;
    index: number;
    setItems: React.Dispatch<
        React.SetStateAction<ProjectMainContentItemType[]>
    >;
};

export function AuthenticationModesCardWrapper({
    item,
    index,
    setItems,
}: AuthenticationModesCardWrapperPropsType): JSX.Element {
    const { t } = useTranslation();

    const {
        defaultLanguage,
        userLanguage,
        isResetOneImageButtonClicked,
        project: { files: images, shopId },
        imageKey,
    } = useSnapshot(generalConfigStore);
    const { mainColor } = useSnapshot(store);
    const { fideltyType } = useSnapshot(storeGlobal);
    const [cardImageKey, setCardImageKey] = React.useState<string>("");
    const [inputValue, setInputValue] = React.useState<string>(
        item.languages[defaultLanguage.name].name
    );

    const imageContent = getImageContentById(
        item.id,
        images,
        defaultLanguage.name
    );
    const imageSrc =
        imageContent?.includes("http") || imageContent?.includes("https")
            ? imageContent
            : `./images/${imageContent}`;

    function handleCheckIconOnClickEvent(index: number) {
        setItems(
            (
                prevState: ProjectMainContentItemType[]
            ): ProjectMainContentItemType[] => {
                let newState = [...prevState];
                const activeItemsCount = newState.filter(
                    (item): boolean => item.active as boolean
                ).length;

                newState = newState.map(
                    (item: ProjectMainContentItemType, itemIndex: number) => {
                        if (itemIndex === index) {
                            return {
                                ...item,
                                active:
                                    activeItemsCount !== 1
                                        ? !item.active
                                        : true,
                            };
                        }

                        return { ...item };
                    }
                );

                setAuthenticationModesOptions(newState);
                return newState;
            }
        );
    }
    function handleInputOnBlurEvent() {
        setItems(
            (
                prevState: ProjectMainContentItemType[]
            ): ProjectMainContentItemType[] => {
                let newState = [...prevState];

                newState = newState.map(
                    (item: ProjectMainContentItemType, itemIndex: number) => {
                        if (itemIndex === index) {
                            return {
                                ...item,
                                languages: {
                                    ...item.languages,
                                    [defaultLanguage.name]: {
                                        ...item.languages[defaultLanguage.name],
                                        name: inputValue,
                                    },
                                },
                            };
                        }

                        return { ...item };
                    }
                );
                setAuthenticationModesOptions(newState);
                return newState;
            }
        );
    }
    function handleResetImagesOnClickEvent(id: string) {
        const imagesArray = [...images];
        const image = imagesArray.find((element) => element.id === id);

        if (image !== undefined) {
            const imageIndex = imagesArray.indexOf(image);
            const localContent = image.content as ImageItemLanguagesType;

            imagesArray[imageIndex] = {
                ...image,
                content: {
                    ...localContent,
                    [defaultLanguage.name]: {
                        ...localContent[defaultLanguage.name],
                        path: localContent[defaultLanguage.name]
                            .defaultImage as string,
                    },
                } as ImageItemLanguagesType,
            };
        }
        setProjectFiles(imagesArray);
        setCardImageKey(uid(imagesArray));
    }

    return (
        <div
            key={index}
            className="d-flex flex-column justify-content-center"
            style={{
                gap: "10px",
                width: "min-content",
                borderBottom: "1px solid #707070",
                opacity: item.active === false ? "40%" : "",
            }}
        >
            <div
                className="d-flex align-items-center justify-content-start align-items-center text-muted"
                style={{ gap: "10px" }}
            >
                {/* <div>
                    <div id="card-title-icon">
                        <HelpIcon height={25} width={25} />
                    </div>
                    <UncontrolledTooltip
                        placement="right"
                        target="card-title-icon"
                    >
                        {t("You can edit it")}
                    </UncontrolledTooltip>
                </div> */}
                <span
                    className="cursor__clz"
                    style={{
                        font: "normal normal normal 15px/20px Nunito Sans",
                    }}
                >
                    {item.languages[userLanguage].defaultContent}
                </span>
            </div>
            <Card
                style={{
                    borderBottom: item.active ? `${mainColor} 10px solid` : "",
                    width: "250px",
                    height: "220px",
                    borderRadius: "20px",
                }}
            >
                <div
                    style={{
                        display: "grid",
                        gridTemplateRows: "0.9fr auto",
                        height: "100%",
                    }}
                >
                    <div
                        style={{
                            display: "grid",
                            gridTemplateColumns: "0.2fr 0.6fr 0.2fr",
                            height: "100%",
                        }}
                    >
                        <CheckIcon
                            onClick={() => handleCheckIconOnClickEvent(index)}
                            className={classnames(
                                "mt-1 ml-1 cmn_chk_selectButton",
                                {
                                    "disable-account-creation-items__clz":
                                        fideltyType[`${shopId}`] ===
                                            GODIA_LOYALTY &&
                                        item.name === "Loyalty card",
                                }
                            )}
                            height={45}
                            width={45}
                            style={{
                                fill: item.active ? mainColor : "#BCBCBC",
                                cursor: "pointer",
                            }}
                        />
                        <div
                            className={classnames(
                                "d-flex justify-content-center align-items-end",
                                {
                                    "disable-account-creation-items__clz":
                                        fideltyType[`${shopId}`] ===
                                            GODIA_LOYALTY &&
                                        item.name === "Loyalty card",
                                }
                            )}
                            onClick={() => handleCheckIconOnClickEvent(index)}
                        >
                            <img
                                alt="img"
                                key={index}
                                src={imageSrc}
                                style={{
                                    width: "100px",
                                    height: "100px",
                                }}
                            />
                        </div>
                        <StyledIconButton
                            title={t("Reset to default image")}
                            outline={true}
                            variant="danger"
                            className="m-0 p-0"
                            icon="RefreshIcon"
                            style={{ height: "20%" }}
                            onClick={() => {
                                handleResetImagesOnClickEvent(item.id);
                                setIsResetOneImageButtonClicked(true);
                            }}
                        >
                            <RefreshIcon
                                height={20}
                                width={20}
                                className="cmn_icn_resetPhoto"
                            />
                        </StyledIconButton>
                    </div>

                    <div
                        className="d-flex justify-content-center align-items-center"
                        style={{ gap: "10px" }}
                    >
                        <div
                            className="d-flex justify-content-center align-items-center index-shadow__clz rounded border cmn_txt_optionOrder"
                            style={{
                                height: "40px",
                                width: "40px",
                            }}
                        >
                            <span style={{ fontSize: "15px" }}>
                                {index + 1}
                                <sup>
                                    {index + 1 === 1 ? t("First") : t("th")}
                                </sup>
                            </span>
                        </div>
                        <fieldset disabled={item.active === false}>
                            <div
                                className="d-flex justify-content-center align-items-center index-shadow__clz rounded border cmn_icn_uploadPhoto"
                                style={{ height: "40px", width: "40px" }}
                            >
                                <Uploader
                                    key={
                                        isResetOneImageButtonClicked === true
                                            ? cardImageKey
                                            : imageKey
                                    }
                                    id={item.id}
                                    activeLanguage={defaultLanguage.name}
                                />
                            </div>
                        </fieldset>
                    </div>
                </div>
            </Card>

            <AvForm
                className="d-flex justify-content-center align-items-center"
                style={{ gap: "5px" }}
            >
                {item.active === true ? (
                    <PencilIcon
                        height={20}
                        width={20}
                        className="cmn_icn_editIcon"
                    />
                ) : null}

                <Input
                    className="border-0 cmn_txt_nameOption"
                    id="card-title"
                    name="card-title"
                    type="text"
                    disabled={item.active === false}
                    style={{
                        font: "normal normal normal 24px/32px Nunito Sans",
                        fontSize: "20px",
                        color: "#000000",
                        backgroundColor: "transparent",
                    }}
                    value={inputValue}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        if (item.active) {
                            return setInputValue(event.target.value);
                        }
                    }}
                    onBlur={handleInputOnBlurEvent}
                />
            </AvForm>
        </div>
    );
}
