import React from "react";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";
import { toast } from "react-toastify";
import { mutate } from "swr";
import { CheckIcon, CheckboxIcon, StyledModal } from "@aureskonnect/react-ui";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import {
    DynamicTable,
    customColumnProps,
} from "@maherunlocker/custom-react-table";
import ErrorToast from "@components/Common/ErrorTost";

import { store } from "@components/VerticalLayout/store";
import {
    store as generalSettingStore,
    setIsAssociateModalOpened,
    setIsAssociateModalClosed,
    setNumberActiveMultiShop,
    setIsAssociatedFidelity,
    setSelectedRow,
    setIds,
    setMessage,
    setFidelity,
} from "../store";

import UniqueRowSelection from "./UniqueRowSelection";
import { CustomIconsAssociateModal } from "./CustomIconsAssociateModal";
import { ConfirmationMessage } from "./ConfirmationMessage";
import { ConfirmationUpdate } from "./ConfirmationUpdate";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";

import "@assets/swal.css";
import "@assets/theme.css";
import "react-toastify/dist/ReactToastify.css";
import { getFidelityAndEditorPos } from "@helpers/general";

export function FidelityAssociateModal({
    setData,
    selectedShopsRows,
    setLimit,
}: any) {
    const { t } = useTranslation();
    const {
        isAssociateModalOpened,
        localShop,
        isAssociateModalClosed,
        numberActiveMultiShop,
        idsLocalShop,
        rowsShop,
        selectedRowsFidelity,
        activeFidelity,
        isAssociatedFidelity,
        fidelity,
    } = useSnapshot(generalSettingStore);
    const { userID, franchiseID, operatorID } = useSnapshot(store);
    const [selectedRows, setSelectedRows] = React.useState<any[]>([]);
    const [error, setError] = React.useState<boolean>(false);
    const [dataIsUpdated, setDataIsUpdated] = React.useState<boolean | number>(
        false
    );
    const [isValidateClickedModal, setIsValidateClickedModal] = React.useState<
        boolean | number
    >(false);
    const [isStateChanged, setIsStateChanged] = React.useState<boolean>(false);
    const [isModalOpened, setIsModalOpened] = React.useState<boolean>(false);
    const [selectedFidelity, setSelectedFidelity] = React.useState<any[]>([]);
    const shopId = 0;
    const i18nextLng = localStorage.getItem("i18nextLng") || "fr";
     const uuidUser = localStorage.getItem("uuidUser");

     const [
        isValidateButtonEnabled,
        setIsValidateButtonEnabled,
    ] = React.useState<boolean>(true);
    const url =
        localShop.length === 1
            ? `${process.env.REACT_APP_API_V2_URL}/settings/general/list/fidelity?userId=${userID}&shopId=${localShop[0]}&franchiseId=${franchiseID}`
            : `${process.env.REACT_APP_API_V2_URL}/settings/general/fidelity?userId=${userID}&shopId=${shopId}&franchiseId=${franchiseID}&type=activated`;

    let arrayOfCustomColumns: customColumnProps[] = [
        {
            indexOFColumn: 0,
            columnName: " ",
            customJsx: UniqueRowSelection,
        },
        {
            indexOFColumn: 5,
            columnName: t("Centralized loyalty"),
            customJsx: (e: any) => (
                <span>
                    {e.selectedRow[t("State")] === true && (
                        <CheckIcon
                            className="mt-1 ml-1 cmn_chk_selectButton"
                            height={30}
                            width={30}
                        />
                    )}
                </span>
            ),
            filterName: t("Centralized loyalty"),
            canFilter: true,
        },
        {
            indexOFColumn: 6,
            columnName: t("Action"),
            customJsx: (e: any) => (
                <CustomIconsAssociateModal
                    element={e}
                    isOneShopClicked={
                        selectedShopsRows.filter(
                            (el: any) => el.subRows !== undefined
                        ).length < 2
                    }
                    selectedFidelity={selectedFidelity}
                />
            ),
        },
    ];

    function checkIfFidelityAssociated(shopId: string) {
        let associated = rowsShop.map((elt: any) => {
            if (elt.shopId === shopId) {
                return elt.subRows;
            } else {
                return null;
            }
        });
        return associated.filter((el: any) => el)[0];
    }
    const ActionColumn = (e: any) => {
        return e.selectedRow.subRows === undefined ? <div></div> : null;
    };

    async function AssociateFidelity() {     
        toast.dismiss();
        let isError: boolean = false;
        let isData: boolean = false;
        let localData: any = [];
        setLimit(1);
        setIsValidateButtonEnabled(false);
     
        for (let element of localShop) {
            let saleModes: any = [];
            await fetch(
                `${process.env.REACT_APP_API_V2_URL}/settings/general/mode_of_sale?userId=${userID}&shopId=${element}&franchiseId=${franchiseID}`
            )
                .then((res) => res.json())
                .then((response) => {
                    saleModes = response.data.map((saleMode: any) => {
                        return {
                            designation: saleMode["Mode of sale"],
                            state: false,
                            saleSupports: saleMode.support_vente.map(
                                (el: any) => {
                                    return {
                                        designation: el,
                                        state: false,
                                    };
                                }
                            ),
                        };
                    });
                });

            let associated = checkIfFidelityAssociated(element);
            let objectData: any = {};
            if (fidelity !== "") {
                let isActive = activeFidelity.find(
                    (element: any) => element.Id === selectedRowsFidelity.Id
                ).state;

                if (
                    associated.findIndex(
                        (elt: any) => elt.Id === selectedRowsFidelity.Id
                    ) === -1 &&
                    associated.length !== 0
                ) {
                    isError = true;
                } else if (
                    associated.findIndex(
                        (elt: any) =>
                            elt.Id === selectedRowsFidelity.Id &&
                            elt.isActive !== isActive
                    ) !== -1 ||
                    associated.length === 0 ||
                    associated.findIndex(
                        (elt: any) =>
                            elt.Id === selectedRowsFidelity.Id &&
                            elt.isActive === isActive
                    ) === -1
                ) {
                    Object.assign(objectData, {
                        [selectedRowsFidelity.Id]: {
                            designation:
                                selectedRowsFidelity[t("Display name")],
                            type: selectedRowsFidelity[t("Loyalty")],
                            number_fidelity_points:
                                selectedRowsFidelity.number_fidelity_points,
                            money_correspondence:
                                selectedRowsFidelity.moneyCorrespondence,
                            devise: selectedRowsFidelity.Devise,
                            pays: selectedRowsFidelity.Pays,
                            authentication_mode:
                                selectedRowsFidelity.authentication_mode,
                            sortedVisibility: saleModes,
                            Login: selectedRowsFidelity.Login,
                            password: selectedRowsFidelity[t("Password")],
                            note: selectedRowsFidelity[t("Remark")],
                            isActive: activeFidelity.find(
                                (element: any) =>
                                    element.Id === selectedRowsFidelity.Id
                            ).state,
                            isArchived:selectedRowsFidelity.fidelityChoiceIndex ===1? [
                                ...selectedRowsFidelity.isArchived,
                                { shopId: element ,status:false},
                            ]:false,
                            shopId: element,
                            dashboardLink:
                                selectedRowsFidelity["Dashboard Link"],
                            user: selectedRowsFidelity.user,
                            apiKey: selectedRowsFidelity["API key"],
                            auresKonnectGroup: selectedRowsFidelity[t("Group")],
                            isCentralizationActive:
                                selectedRowsFidelity[t("State")],
                            isVisibiliteActive:
                                selectedRowsFidelity[t("isVisibiliteActive")],

                            fidelityChoiceIndex:
                                selectedRowsFidelity.fidelityChoiceIndex,
                        },
                    });
                }
                    
                let dataFidelity = {
                    userId: userID,
                    shopId: [element],
                    franchiseId: franchiseID,
                    operatorId: operatorID,
                     uuidUser: uuidUser,
                    data: objectData,
                    affectation: true,
                };

                if (Object.keys(dataFidelity.data).length !== 0) {
                    isData = true;
                }
                localData.push(
                    fetch(
                        `${process.env.REACT_APP_API_V2_URL}/settings/general/fidelity`,
                        {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json",
                            },
                            body: JSON.stringify(dataFidelity),
                        }
                    ).then((response) => response.json())
                );
            }
            try {
                mutate(
                    `${process.env.REACT_APP_API_V2_URL}/settings/general/fidelity`,
                    await Promise.all(localData).then((result) => {
                        result.forEach((element: any) => {
                            if (element.error === true) setError(true);
                        });
                    })
                );
            } catch (e) {
                toast.error(`${t("There's an error")!}`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    theme: "colored",
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            }
        }
        if (error === false) {
            if (isError && !isData) {
                ErrorToast(
                    t(
                        "Please note that one or more fidelity settings could not be assigned. A store cannot have more than one loyalty setting."
                    )
                );
            } else if (isData && isError) {
                await getFidelityAndEditorPos(franchiseID, uuidUser as string);
                toast.success(
                    `${t("Fidelity assignment was successfully assigned")!}`,
                    {
                        position: toast.POSITION.TOP_CENTER,
                        theme: "colored",
                        icon: (
                            <CheckboxIcon height={25} width={25} fill="white" />
                        ),
                    }
                );
                setIsValidateButtonEnabled(true);
                ErrorToast(
                    t(
                        "Please note that one or more fidelity settings could not be assigned. A store cannot have more than one loyalty setting."
                    )
                );
                setLimit(2);
            } else {
                await getFidelityAndEditorPos(franchiseID, uuidUser as string);
                toast.success(
                    `${t("Fidelity assignment was successfully assigned")!}`,
                    {
                        position: toast.POSITION.TOP_CENTER,
                        theme: "colored",
                        icon: (
                            <CheckboxIcon height={25} width={25} fill="white" />
                        ),
                    }
                );
            }

            setNumberActiveMultiShop(
                numberActiveMultiShop + selectedRows.length * localShop.length
            );

            setIsAssociateModalOpened(!isAssociateModalOpened);
            setIsAssociateModalClosed(!isAssociateModalClosed);
            setData(true);
        }
        setData(true);
    }

    function CountIds() {
        let number = 0;
        idsLocalShop.forEach((el: any) => {
            selectedRows.forEach((v: any) => {
                if (v.Id === el) {
                    number = number + 1;
                }
            });
        });
        setIds(number);
    }

    function checkIfFiscalYearExistAndArchived() {
        let archived: any[] = [];
        if (rowsShop.length > 0) {
            rowsShop.forEach((element: any) => {
                if (element.archivedFidelity !== undefined) {
                    element.archivedFidelity.forEach((el: any) => {
                        archived.push(el.Id);
                    });
                }
            });
        }
        return archived;
    }

    React.useEffect(() => {
        let local: any = [];
        selectedShopsRows.forEach((el: any) => {
            if (el?.subRows !== undefined)
                el?.subRows.forEach((element: any) => {
                    local.push(element);
                });
        });
        setSelectedFidelity(local);
        let notChanged: any = [];
        local.forEach((el: any) => {
            if (selectedRowsFidelity.Id === el.Id) {
                let test: any = activeFidelity.find(
                    (element: any) => element.Id === el.Id
                );
                notChanged.push(test.state === el.isActive);
            }
        });
        setIsStateChanged(notChanged.includes(false));
    }, [selectedShopsRows, selectedRowsFidelity, activeFidelity]);

    React.useEffect(() => {
        if (rowsShop[0] !== undefined) {
            if (rowsShop[0]?.subRows !== undefined) {
                localShop.length > 1
                    ? setFidelity("")
                    : setFidelity(
                          rowsShop[0]?.subRows[0] !== undefined
                              ? rowsShop[0]?.subRows[0]?.Id
                              : rowsShop[0].Id
                      );
            } else if (rowsShop[1]?.subRows !== undefined) {
                localShop.length > 1
                    ? setFidelity("")
                    : setFidelity(
                          rowsShop[1]?.subRows[1] !== undefined
                              ? rowsShop[1]?.subRows[1]?.Id
                              : rowsShop[0].Id
                      );
            }
        }
    }, [rowsShop, localShop]);

    React.useEffect(() => {
        if (isValidateClickedModal === true) {
            AssociateFidelity();
        }
        // eslint-disable-next-line
    }, [isValidateClickedModal]);
    
    return (
        <StyledModal
            toggle={true}
            isOpen={isAssociateModalOpened!}
            centered
            className="modal-wrapper__clz"
            backdrop="static"
            fade={false}
            scrollable
        >
            <ModalHeader
                className="text-capitalize"
                toggle={() => {
                    setIsAssociateModalOpened(!isAssociateModalOpened);
                    setIsAssociateModalClosed(!isAssociateModalClosed);
                    setData(true);
                }}
            >
                <span
                    className="text-uppercase fed_txt_AFloyaltyList"
                    style={{
                        fontFamily: "Segoe UI Semibold",
                        color: "#2b2828",
                        fontSize: "20px",
                    }}
                >
                    {t("List of fidelity")}
                </span>
            </ModalHeader>
            <ModalBody>
                <DynamicTable
                    url={url}
                    requestHeader={{ "Accept-Language": i18nextLng }}
                    customSelect
                    setSelectedRows={setSelectedRows}
                    canResize
                    canMovedCheckboxLeftOnExpand
                    actionColumn={ActionColumn}
                    showGlobalFilter
                    setDataIsUpdated={setDataIsUpdated}
                    dataIsUpdated={dataIsUpdated}
                    arrayOfCustomColumns={arrayOfCustomColumns}
                    elevationTable={1}
                    minHeight="500px"
                    maxHeight="500px"
                    name="associateFidelity"
                />
            </ModalBody>
            <ModalFooter className="border-top-0">
                <CustomSecondaryColorButton
                    outline
                    variant="light"
                    className="fed_btn_AFcancel"
                    onClick={() => {
                        setIsAssociateModalOpened(!isAssociateModalOpened);
                        setIsAssociateModalClosed(!isAssociateModalClosed);
                        setData(true);
                    }}
                    rounded
                >
                    {t("Cancel")}
                </CustomSecondaryColorButton>
                <CustomMainColorButton
                    rounded
                    variant="primary"
                    className="fed_btn_AFvalidate"
                    disabled={!isValidateButtonEnabled}
                    onClick={() => {
                        let archived = checkIfFiscalYearExistAndArchived();
                        CountIds();
                        if (fidelity === "" || fidelity === undefined) {
                            ErrorToast(
                                t(
                                    "Attention ! You must select least one fidelity"
                                )
                            );
                        } else if (isStateChanged) {
                            setIsModalOpened(!isModalOpened);
                        } else {
                            try {
                                setSelectedRow(selectedRows);

                                if (
                                    archived.includes(selectedRowsFidelity.Id)
                                ) {
                                    setIsAssociatedFidelity(true);
                                    setMessage(
                                        t(
                                            "Attention! One or more selected fidelity are already assigned and archived at the store level.Would you unarchive them?"
                                        )
                                    );
                                    // eslint-disable-next-line
                                    throw "Break";
                                }

                                AssociateFidelity();
                            } catch (e) {
                                if (e !== "Break") throw e;
                            }
                        }
                    }}
                >
                    {t("Validate")}
                </CustomMainColorButton>
            </ModalFooter>
            {isModalOpened ? (
                <ConfirmationMessage
                    msg={t(
                        "Warning! This change will affect specific settings in the store(s).\n\nDo you want to confirm the modification?"
                    )}
                    setIsModalOpened={setIsModalOpened}
                    isModalOpened={isModalOpened}
                    setIsValidateClickedModal={setIsValidateClickedModal}
                />
            ) : null}
            {isAssociatedFidelity ? (
                <ConfirmationUpdate
                    isModalOpened={isAssociatedFidelity}
                    setIsModalOpened={setIsAssociatedFidelity}
                    setIsValidateClickedModal={setIsValidateClickedModal}
                />
            ) : null}
        </StyledModal>
    );
}
