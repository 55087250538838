import React from "react";
import { useTranslation } from "react-i18next";
import {
    CheckboxIcon,
    CrossIcon,
    StyledLabel,
    StyledTextInput,
} from "@aureskonnect/react-ui";
import { AvForm, AvGroup } from "availity-reactstrap-validation";
import { Modal } from "reactstrap";
import { toast } from "react-toastify";
import { useSnapshot } from "valtio";
import { mutate } from "swr";

import { store } from "@components/VerticalLayout/store";

import {
    CustomCardBody,
    CustomCardFooter,
    CustomCardHeader,
} from "../../../../components/Common/CustomCardStyled";
import { capitalize } from "../../../../helpers/general";
import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";
import { httpRegex } from "@constants/index";

import "@assets/swal.css";
import "@assets/theme.css";
import "react-toastify/dist/ReactToastify.css";
import "./index.css";

type AggregatorFormType = {
    isModalOpened: boolean;
    setIsModalOpened: Function;
    rowData: any;
    setDataIsUpdated: Function;
};

export default function AggregatorConfigure({
    isModalOpened,
    setIsModalOpened,
    rowData,
    setDataIsUpdated,
}: AggregatorFormType) {
    const { t } = useTranslation();
    const { userID, operatorID, franchiseID } = useSnapshot(store);
    const uuidUser = localStorage.getItem("uuidUser");

    const [isInvalidWebhook, setIsInvalidWebhook] = React.useState<boolean>(
        false
    );
    const [webhook, setWebhook] = React.useState<string>(rowData.Webhook);

    async function handleValidate() {
        if (!isInvalidWebhook) {
            toast.dismiss();
            const apiUrlUpdate = `${process.env.REACT_APP_API_V2_URL}/settings/general/aggregator`;
            delete rowData.Webhook;
            let dataConfig = {
                userId: userID,
                shopId: [rowData.shopId],
                franchiseId: franchiseID,
                operatorId: operatorID,
                uuidUser: uuidUser,
                data: {
                    [rowData.Id]: {
                        ...rowData,
                        aggregator: rowData[t("Aggregator")],
                        note: rowData[t("Remark")],
                        webhook: webhook,
                    },
                },
            };

            const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(dataConfig),
            };
            try {
                mutate(
                    apiUrlUpdate,
                    await fetch(apiUrlUpdate, requestOptions)
                        .then((response) => response.json())
                        .then((data) => {
                            if (data.error) {
                                throw Error("error");
                            }
                            toast.success(
                                `${t(
                                    "The Aggregator setting has been successfully modified"
                                )!}`,
                                {
                                    position: toast.POSITION.TOP_CENTER,
                                    theme: "colored",
                                    icon: (
                                        <CheckboxIcon
                                            height={25}
                                            width={25}
                                            fill="white"
                                        />
                                    ),
                                }
                            );
                            setIsModalOpened(false);
                            setDataIsUpdated!(true);
                        })
                );
            } catch (e) {
                toast.error(`${t("There's an error")!}`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    theme: "colored",
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            }
        }
    }

    return (
        <React.Fragment>
            <Modal
                centered
                isOpen={isModalOpened}
                className="information_fidelity_modal__clz mt-5"
            >
                <AvForm className="card information_fidelity__clz">
                    <CustomCardHeader className="d-flex align-items-center justify-content-between">
                        <h5 className="pt-3">
                            {capitalize(t("Aggregator setting"))}
                        </h5>
                        <CrossIcon
                            className="icon-close-notification__clz"
                            height={13}
                            width={13}
                            onClick={() => {
                                setIsModalOpened(false);
                            }}
                        />
                    </CustomCardHeader>
                    <CustomCardBody
                        className="pl-3 pr-5"
                        style={{
                            maxHeight: "500px",
                            overflowY: " scroll",
                        }}
                    >
                        <AvGroup>
                            <StyledLabel className="mt-3" htmlFor="name">
                                {t("Webhook")}
                            </StyledLabel>
                            <StyledTextInput
                                autocomplete="off"
                                id="Webhook"
                                name="Webhook"
                                placeholder={t("Write")}
                                type="text"
                                onChange={(e: any) => {
                                    if (
                                        !e.target.value.match(httpRegex) &&
                                        e.target.value !== ""
                                    ) {
                                        setIsInvalidWebhook(true);
                                    } else if (
                                        e.target.value !== "" &&
                                        e.target.value.match(httpRegex)
                                    ) {
                                        setWebhook(e.target.value);
                                        setIsInvalidWebhook(false);
                                    }
                                }}
                                value={webhook}
                            />
                            {isInvalidWebhook && (
                                <div style={{ color: "red" }}>
                                    {t("Webhook is invalid")}
                                </div>
                            )}
                        </AvGroup>
                    </CustomCardBody>
                    <CustomCardFooter className="pt-1 pb-2 mt-4">
                        <CustomSecondaryColorButton
                            outline
                            rounded
                            className="mr-2 fed_btn_FRcancel cmn_btn_cancel"
                            variant="light"
                            onClick={() => {
                                setIsModalOpened(false);
                            }}
                        >
                            <span> {t("Cancel")}</span>
                        </CustomSecondaryColorButton>
                        <CustomMainColorButton
                            variant="primary"
                            onClick={() => {
                                handleValidate();
                            }}
                            rounded
                        >
                            {t("Validate")}
                        </CustomMainColorButton>
                    </CustomCardFooter>
                </AvForm>
            </Modal>
        </React.Fragment>
    );
}
