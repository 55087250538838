import React from "react";
import { useTranslation } from "react-i18next";
import ReactSwitch from "react-switch";
import { useSnapshot } from "valtio";

import {
    setTicketHeaderSaleModeBold,
    setTicketHeaderSaleModeBlackBackround,
    setTicketHeaderName_phone_number_bold,
    setTicketHeaderLogoKitchen,
    setTicketHeaderLogoCustomer,
    StoreGeneral,
    setTicketHeaderStyleOrderNumber,
    setTicketHeaderPathCustomer,
    setTicketHeaderPathKitchen,
} from "./Hook/store";

import "../index.css";
import { Uploader } from "./upload";

type TicketHeaderType = {
    changeHandlerCustomer: React.ChangeEventHandler<HTMLInputElement>;
    changeHandler: React.ChangeEventHandler<HTMLInputElement>;
    rowData: any;
};

export default function TicketHeader({
    changeHandlerCustomer,
    changeHandler,
    rowData,
}: TicketHeaderType) {
    const { t } = useTranslation();
    const {
        ticketHeaderSaleModeBlackBackround,
        typeLogoCustomer,
        typeLogoKitchen,
        ticketHeaderSaleModeBold,
        ticketHeaderName_phone_number_bold,
        ticketHeaderPathCustomer,
        ticketHeaderPathKitchen,
        ticketHeaderLogoCustomer,
        ticketHeaderLogoKitchen,
        ticketHeaderStyleOrderNumber,
    } = useSnapshot(StoreGeneral);

    const isJpgOrPng =
        typeLogoCustomer === "image/jpeg" ||
        typeLogoCustomer === "image/png" ||
        typeLogoCustomer === "image/ico" ||
        typeLogoCustomer === "image/jpg";
    const isJpgOrPngKitchen =
        typeLogoKitchen === "image/jpeg" ||
        typeLogoKitchen === "image/png" ||
        typeLogoKitchen === "image/ico" ||
        typeLogoKitchen === "image/jpg";
    return (
        <React.Fragment>
            <div className="d-flex  align-items-center justify-content-between px-1 pt-2 pb-1">
                <b>{t("Ticket header")}</b>
            </div>
            <div className="d-flex  align-items-center justify-content-between p-1">
                <div>{t("Print sale mode in bold")}</div>
                <div className=" float-right mr-4">
                    <ReactSwitch
                        uncheckedIcon={false}
                        checkedIcon={false}
                        handleDiameter={26}
                        offColor="#f7b4b8"
                        offHandleColor="#E30613"
                        checked={ticketHeaderSaleModeBold}
                        onChange={(e: any) => {
                            setTicketHeaderSaleModeBold(
                                !ticketHeaderSaleModeBold
                            );
                        }}
                        onColor="#c2eddd"
                        onHandleColor="#34C38F"
                        width={55}
                        height={20}
                        className="afa_icn_boldSaleMode"
                    />
                </div>
            </div>
            <div className="d-flex  align-items-center justify-content-between p-1">
                <div>{t("Print sale mode in  with black background")}</div>
                <div className=" float-right mr-4">
                    <ReactSwitch
                        uncheckedIcon={false}
                        checkedIcon={false}
                        handleDiameter={26}
                        offColor="#f7b4b8"
                        offHandleColor="#E30613"
                        checked={ticketHeaderSaleModeBlackBackround}
                        onChange={(e: any) => {
                            setTicketHeaderSaleModeBlackBackround(
                                !ticketHeaderSaleModeBlackBackround
                            );
                        }}
                        onColor="#c2eddd"
                        onHandleColor="#34C38F"
                        width={55}
                        height={20}
                        className="afa_icn_boldSaleModeWithBlackBackground"
                    />
                </div>
            </div>
            <div className="d-flex  align-items-center justify-content-between p-1">
                <div>{t("Print company name and phone number in bold")}</div>
                <div className=" float-right mr-4">
                    <ReactSwitch
                        uncheckedIcon={false}
                        checkedIcon={false}
                        handleDiameter={26}
                        offColor="#f7b4b8"
                        offHandleColor="#E30613"
                        checked={ticketHeaderName_phone_number_bold}
                        onChange={(e: any) => {
                            setTicketHeaderName_phone_number_bold(
                                !ticketHeaderName_phone_number_bold
                            );
                        }}
                        onColor="#c2eddd"
                        onHandleColor="#34C38F"
                        width={55}
                        height={20}
                        className="afa_icn_namePhoneAndCompanyInBold"
                    />
                </div>
            </div>
            <div className="d-flex  align-items-center justify-content-between p-1">
                <div>
                    {t("Print the order number in bold and with a larger size")}
                </div>
                <div className=" float-right mr-4">
                    <ReactSwitch
                        uncheckedIcon={false}
                        checkedIcon={false}
                        handleDiameter={26}
                        offColor="#f7b4b8"
                        offHandleColor="#E30613"
                        checked={ticketHeaderStyleOrderNumber}
                        onChange={(e: any) => {
                            setTicketHeaderStyleOrderNumber(
                                !ticketHeaderStyleOrderNumber
                            );
                        }}
                        onColor="#c2eddd"
                        onHandleColor="#34C38F"
                        width={55}
                        height={20}
                        className="afa_icn_boldSaleMode"
                    />
                </div>
            </div>

            {rowData.tickets.label === "Customer" ? (
                <div>
                    <div className="d-flex  align-items-center justify-content-between p-1">
                        <div>{t("Print logo on customer receipt")}</div>
                        <div className=" float-right mr-4">
                            <b>
                                <ReactSwitch
                                    uncheckedIcon={false}
                                    checkedIcon={false}
                                    handleDiameter={26}
                                    offColor="#f7b4b8"
                                    offHandleColor="#E30613"
                                    checked={ticketHeaderLogoCustomer}
                                    onChange={(e: any) => {
                                        setTicketHeaderLogoCustomer(
                                            !ticketHeaderLogoCustomer
                                        );
                                    }}
                                    onColor="#c2eddd"
                                    onHandleColor="#34C38F"
                                    width={55}
                                    height={20}
                                    className="afa_icn_logoOnCustomerTicket"
                                />
                            </b>
                        </div>
                    </div>
                    <Uploader
                        active={ticketHeaderLogoCustomer}
                        file={ticketHeaderPathCustomer}
                        setFile={setTicketHeaderPathCustomer}
                        ticketType={"logoCustomer"}
                        disabled={!ticketHeaderLogoCustomer}
                    />
                </div>
            ) : null}

            {!isJpgOrPng && typeLogoCustomer !== "" ? (
                <div
                    className="ml-2"
                    style={{
                        width: "100%",
                        marginTop: "0.25rem",
                        fontSize: "80%",
                        color: "#f46a6a",
                    }}
                >
                    {t("Please insert a png, jpg,jpeg or ico image.")}
                </div>
            ) : null}
            {rowData.tickets.label === "Production" ? (
                <div>
                    <div className="d-flex  align-items-center justify-content-between p-1">
                        {t("Print logo on kitchen ticket")}
                        <div className=" float-right mr-4">
                            <ReactSwitch
                                uncheckedIcon={false}
                                checkedIcon={false}
                                handleDiameter={26}
                                offColor="#f7b4b8"
                                offHandleColor="#E30613"
                                checked={ticketHeaderLogoKitchen}
                                onChange={(e: any) => {
                                    setTicketHeaderLogoKitchen(
                                        !ticketHeaderLogoKitchen
                                    );
                                }}
                                onColor="#c2eddd"
                                onHandleColor="#34C38F"
                                width={55}
                                height={20}
                            />
                        </div>
                    </div>
                    <Uploader
                        active={ticketHeaderLogoKitchen}
                        file={ticketHeaderPathKitchen}
                        setFile={setTicketHeaderPathKitchen}
                        ticketType={"logoKitchen"}
                        disabled={!ticketHeaderLogoKitchen}
                    />
                </div>
            ) : null}

            {!isJpgOrPngKitchen && typeLogoKitchen !== "" ? (
                <div
                    className="ml-2"
                    style={{
                        width: "100%",
                        marginTop: "0.25rem",
                        fontSize: "80%",
                        color: "#f46a6a",
                    }}
                >
                    {t("Please insert a png, jpg,jpeg or ico image.")}
                </div>
            ) : null}
        </React.Fragment>
    );
}
