import React from "react";
import { useTranslation } from "react-i18next";
import ReactSwitch from "react-switch";
import { useSnapshot } from "valtio";
import {
    generalConfigStore,
    setIsGift,
    setIsRewards,
    setIsStateChanged,
    setIsEnterOrScanPromoCode,
    setIsPagePromoCodeActive,
} from "@store";
import { GODIA_LOYALTY } from "@constants/index";
import { store } from "@components/VerticalLayout/store";

export function MainContent(): JSX.Element {
    const { t } = useTranslation();

    const { project } = useSnapshot(generalConfigStore);
    const { fideltyType } = useSnapshot(store);

    const isEnterOrScanPromoCode = ((((project.template.pages.subSteps[
        "customerBenefit"
    ] as unknown) as CustomerBenefitPageType)
        .options as CustomerBenefitOptionsType).promoCode as RewardType).active;

    const isGifts = ((((project.template.pages.subSteps[
        "customerBenefit"
    ] as unknown) as CustomerBenefitPageType)
        .options as CustomerBenefitOptionsType).gifts as RewardType).active;
    const isRewards = ((((project.template.pages.subSteps[
        "customerBenefit"
    ] as unknown) as CustomerBenefitPageType)
        .options as CustomerBenefitOptionsType).rewards as RewardType).active;

    function activeLoyaltyBenefitsSwitchOnClickEvent(
        setState: Function,
        state: boolean,
        name: string
    ) {
        setState(!state);

        if (fideltyType[`${project.shopId}`] === GODIA_LOYALTY) {
            let isEnterOrScanPromoCodeIsGift =
                isEnterOrScanPromoCode && isGifts;

            const newIsRewards = name === "isRewards" ? !state : isRewards;
            if (name === "isEnterOrScanPromoCode" || name === "isGifts") {
                isEnterOrScanPromoCodeIsGift = !state;
                setIsGift(!state);
                setIsEnterOrScanPromoCode(!state);
            }

            // setIsPaymentWithLoyaltyBalance(!isEnterOrScanPromoCodeIsGift && !newIsRewards);
            setIsPagePromoCodeActive(
                isEnterOrScanPromoCodeIsGift || newIsRewards
            );
            setIsStateChanged(true);
        }
    }

    return (
        <div
            className="mx-2 m-2 rounded border"
            style={{ borderColor: "#CECECE" }}
        >
            <div
                className="text-left px-4 d-flex align-items-center cursor__clz"
                style={{
                    backgroundColor: "#EDEDED",
                    height: "40px",
                    fontSize: "20px",
                }}
            >
                {t("Loyalty benefit")}
            </div>
            <div className="p-4">
                <div className="d-flex flex-row justify-content-between align-items-center">
                    {t("Scan or enter a promo code")}

                    <ReactSwitch
                        className="ksk_chk_expertTicketDematerialize"
                        uncheckedIcon={false}
                        checkedIcon={false}
                        handleDiameter={26}
                        offColor="#f7b4b8"
                        offHandleColor="#E30613"
                        checked={isEnterOrScanPromoCode}
                        onChange={() => {
                            activeLoyaltyBenefitsSwitchOnClickEvent(
                                setIsEnterOrScanPromoCode,
                                isEnterOrScanPromoCode,
                                "isEnterOrScanPromoCode"
                            );
                        }}
                        onColor="#c2eddd"
                        onHandleColor="#34C38F"
                        width={50}
                        height={20}
                    />
                </div>

                <div className="py-2 d-flex flex-rows justify-content-between align-items-center">
                    <div>{t("Gifts")}</div>
                    <ReactSwitch
                        uncheckedIcon={false}
                        checkedIcon={false}
                        handleDiameter={26}
                        offColor="#f7b4b8"
                        offHandleColor="#E30613"
                        checked={isGifts}
                        onChange={() => {
                            activeLoyaltyBenefitsSwitchOnClickEvent(
                                setIsGift,
                                isGifts,
                                "isGifts"
                            );
                        }}
                        onColor="#c2eddd"
                        onHandleColor="#34C38F"
                        width={50}
                        height={20}
                    />
                </div>
                <div className="py-2 d-flex flex-rows justify-content-between align-items-center">
                    <div>{t("Reward / point store")}</div>
                    <ReactSwitch
                        uncheckedIcon={false}
                        checkedIcon={false}
                        handleDiameter={26}
                        offColor="#f7b4b8"
                        offHandleColor="#E30613"
                        checked={isRewards}
                        onChange={() => {
                            activeLoyaltyBenefitsSwitchOnClickEvent(
                                setIsRewards,
                                isRewards,
                                "isRewards"
                            );
                        }}
                        onColor="#c2eddd"
                        onHandleColor="#34C38F"
                        width={50}
                        height={20}
                    />
                </div>
            </div>
        </div>
    );
}
