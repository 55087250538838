import React, { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { DynamicTable } from "@maherunlocker/custom-react-table";
import { useSnapshot } from "valtio";
import { ConfirmationInitializationMessage } from "@components/Common/ConfirmationInitializationMessage";
import { CustomIconsComponent } from "./CustomIconsComponent";
import ActionColumnVatRate from "./ActionColumnVatRate";
import { VatRateAssociateModal } from "./VatRateAssociateModal";
import OriginHistory from "../VatRatesShop/OriginHistory";
import { MessageConfirmation } from "./MessageConfimation";

import {
    storeVatRate,
    setIsAddVatRateClicked,
    setLocalShop,
    setIsAssociateModalOpened,
    setListLinks,
    setIsAddVatRateOriginHistoryShopClicked,
    setVatRateId,
    setIsModalMessageConfirmationOpened,
    setListNumberOrigin,
    setListNumberBoutique,
    setAssociatedShops,
    setDataFranchise,
    setDataShops,
    setDataIsUpdatedShops,
    setAssociatedTva,
} from "../store";
import { store } from "../../../../components/VerticalLayout/store";
import CustomColumnDisplay from "@components/Common/CustomColumnDisplay";
import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { store as StoreConfirmationActivationDeactivation } from "@components/Common/ConfirmationActivationDeactivationMessageModal/store";
import { ConfirmationActivationDeactivationMessageModal } from "@components/Common/ConfirmationActivationDeactivationMessageModal";

import "./index.css";
import "@assets/swal.css";
import "@assets/theme.css";
import "react-toastify/dist/ReactToastify.css";

export default function VatRates({
    typeDisplayShop,
    setTypeDisplayShop,
    setLimit,
}: any) {
    const { activationDeactivationMessageModalOpened } = useSnapshot(
        StoreConfirmationActivationDeactivation
    );
    const { userID } = useSnapshot(store);
    const { franchiseID, oneShop } = useSnapshot(store);
    const {
        isAddVatRateClicked,
        isAddVatRateOriginHistoryShopClicked,
        dataIsUpdatedShops,
        isModalMessageConfirmationOpened,
        dataFranchise,
        dataShops,
        modify,
    } = useSnapshot(storeVatRate);
    const i18nextLng = localStorage.getItem("i18nextLng") || "fr";
    const uuidUser = localStorage.getItem("uuidUser");

    const { t } = useTranslation();
    const [
        isAddNewVatRateButtonClicked,
        setIsAddNewVatRateButtonClicked,
    ] = useState(false);
    const [, setVatRate] = useState<any[]>([]);
    const [selectedIds, setSelectedIds] = React.useState<any[]>([]);
    const [, setEditedData] = React.useState<any>([]);

    const [filterActive, setLocalFilterActive] = React.useState<boolean>(false);
    const [isShopSelected, setIsShopSelected] = React.useState<boolean>(false);
    const [
        isInInitializationModalOpened,
        setIsInInitializationModalOpened,
    ] = React.useState<boolean>(false);
    const [isModalOpened, setIsModalOpened] = React.useState<boolean>(false);
    const [isHistoryModalOpened, setIsHistoryModalOpened] = React.useState<
        boolean
    >(false);

    const [selectedRows, setSelectedRows] = React.useState<any[]>([]);
    const [, setIsEdited] = React.useState<boolean>(false);
    const [data, setData] = useState<any>({});

    async function getInfoNumberVatRate() {
        let archived: number = 0;
        let notArchived: number = 0;

        if (dataFranchise !== undefined && dataFranchise.length > 0) {
            dataFranchise.forEach((el: any) => {
                if (el.archived === true) {
                    archived++;
                } else {
                    notArchived++;
                }
            });
        }
        setListNumberOrigin({
            nbrArchived: archived,
            nbrNotArchived: notArchived,
        });

        let Marchived: number = 0;
        let MnotArchived: number = 0;

        if (dataShops !== undefined && dataShops.length > 0) {
            dataShops.forEach((el: any) => {
                if (el?.subRows?.length > 0) {
                    el.subRows.forEach((elx: any) => {
                        if (elx.archived === true) {
                            Marchived++;
                        } else {
                            MnotArchived++;
                        }
                    });
                }
            });
        }

        setListNumberBoutique({
            nbrArchived: Marchived,
            nbrNotArchived: MnotArchived,
        });
    }
    React.useEffect(() => {
        getInfoNumberVatRate();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataIsUpdatedShops]);

    React.useEffect(() => {
        setVatRate(selectedRows);

        let arrayIds: any = [];

        if (Array.isArray(selectedRows)) {
            let traceIds: any = [];
            selectedRows.forEach((element: any) => {
                if (
                    element[t("Shop")] === undefined &&
                    traceIds.indexOf(`${element.Id}${element.shopId}`) === -1
                ) {
                    arrayIds.push(element.Id);
                    traceIds.push(`${element.Id}${element.shopId}`);
                }
            });
            setSelectedIds(arrayIds);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedRows]);

    React.useEffect(() => {
        let localShop: any = [];

        selectedRows.forEach((el: any) => {
            if (el[t("Shop")] !== "" && el[t("Shop")] !== undefined)
                localShop.push({
                    id: el.shopId,
                    label: el[t("Shop")],
                    pays: el[t("Country")],
                });
        });
        setLocalShop(localShop);

        setIsShopSelected(localShop.length !== 0);
    }, [selectedRows, t]);

    const NbreVatRate = (e: any) => {
        return e.e.selectedRow?.subRows !== undefined ? (
            <h6 className="badge__clz tva_icn_BTcounter">
                {e.e.selectedRow[t("Shop")] !== ""
                    ? e.e.selectedRow.subRows?.length
                    : 0}
            </h6>
        ) : null;
    };
    const ListOfPays = (e: any) => {
        var list = "";
        let listPays: any = [];
        listPays = e.e.selectedRow[t("Country")]?.labels;

        if (Array.isArray(listPays)) {
            listPays.forEach((n: any) => {
                list += n + `,`;
            });
        }

        if (
            e.e.selectedRow[t("Shop")] === undefined ||
            e.e.selectedRow[t("Shop")] === ""
        ) {
            let arr = e.e.selectedRow[t("Country")];

            list = JSON.parse(JSON.stringify(arr)).join(",");
        } else {
            if (list === "") {
                list = e.e.selectedRow[t("Country")];
            }
        }
        return <CustomColumnDisplay text={`${list}`} />;
    };

    type CustomColumnProps = {
        indexOFColumn: number;
        columnName: string;
        customJsx: React.ReactNode;
    };

    let arrayOfCustomColumnsMultiShop: CustomColumnProps[] = [
        {
            indexOFColumn: 2,
            columnName: t("Nbre VAT"),

            customJsx: (e: any) => <NbreVatRate e={e} />,
        },
        {
            indexOFColumn: 4,
            columnName: t("VAT rate"),

            customJsx: (e: any) =>
                e.selectedRow[t("VAT rate")] !== "" ? (
                    <Fragment>{e.selectedRow[t("VAT rate")]} %</Fragment>
                ) : (
                    ""
                ),
        },
        {
            indexOFColumn: 997,
            columnName: t("Country"),

            customJsx: (e: any) => <ListOfPays e={e} setVatRate={setVatRate} />,
        },
        {
            indexOFColumn: 5,
            columnName: t("Tag"),
            customJsx: (e: any) => (
                <CustomColumnDisplay
                    text={
                        Array.isArray(e.selectedRow.tag)
                            ? JSON.parse(
                                  JSON.stringify(e.selectedRow.tag)
                              ).join(",")
                            : ""
                    }
                />
            ),
        },
        {
            indexOFColumn: 999,
            columnName: t("Actions"),
            customJsx: (e: any) => (
                <CustomIconsComponent
                    e={e}
                    setListLinks={setListLinks}
                    setEditedData={setEditedData}
                    setIsModalOpened={setIsModalOpened}
                    isModalOpened={isModalOpened}
                    isAddVatRateClicked={isAddVatRateClicked}
                    setSelectedRows={setSelectedRows}
                    selectedRows={selectedRows}
                    setVatRate={setVatRate}
                    setIsEdited={setIsEdited}
                    franchiseID={franchiseID}
                    userID={userID}
                    selectedIds={selectedIds}
                    setIsAddVatRateOriginHistoryClicked={
                        setIsAddVatRateOriginHistoryShopClicked
                    }
                    isAddVatRateOriginHistoryClicked={
                        isAddVatRateOriginHistoryShopClicked
                    }
                    typeDisplayShop={typeDisplayShop}
                    setTypeDisplayShop={setTypeDisplayShop}
                />
            ),
        },
    ];
    const ActionColumn = (e: any) => {
        return e.selectedRow.subRows === undefined ? <div></div> : null;
    };

    React.useEffect(() => {
        if (filterActive) setIsAddVatRateClicked(false);
    }, [filterActive]);
    React.useEffect(() => {
        let associatedTva: any[] = [];
        if (selectedRows.length > 0) {
            if (selectedRows[0][t("Shop")] !== undefined) {
                setAssociatedShops(selectedRows);
            }
            selectedRows.forEach((el: any) => {
                for (let i in el.subRows) {
                    associatedTva.push(el.subRows[i]);
                }
            });
        }
        setAssociatedTva(associatedTva);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedRows]);
    React.useEffect(() => {
        setDataFranchise(data.othersData?.dataFranchise);
        setDataShops(data.othersData?.dataBoutique);
        getInfoNumberVatRate();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    return (
        <React.Fragment>
            <div
                style={{
                    display: "grid",
                    minHeight: oneShop ? "650px" : "600px",
                    gridTemplateColumns: "auto",
                    gridColumnGap: "4px",
                    border: 0,
                }}
            >
                <DynamicTable
                    url={`${process.env.REACT_APP_API_V2_URL}/settings/general/vatRate/associated?userId=${userID}&franchiseId=${franchiseID}&typeDisplay=${typeDisplayShop}&uuidUser=${uuidUser}`}
                    requestHeader={{ "Accept-Language": i18nextLng }}
                    canExpand={true}
                    canSelect
                    customSelect
                    setData={setData}
                    setSelectedRows={setSelectedRows}
                    canMovedCheckboxLeftOnExpand
                    customJsxSideFilterButton={
                        <React.Fragment>
                            {modify === true && (
                                <div className="d-flex justify-content-start align-items-center">
                                    <CustomMainColorButton
                                        className="w-100 ml-3 tva_btn_BTassignVatRate"
                                        rounded
                                        variant="primary"
                                        disabled={
                                            isShopSelected &&
                                            typeDisplayShop === "notArchived"
                                                ? false
                                                : true
                                        }
                                        onClick={() => {
                                            setVatRate([]);
                                            setIsAssociateModalOpened(true);

                                            setLocalFilterActive(false);
                                        }}
                                    >
                                        {" "}
                                        {t("Assign a vat rate")}
                                    </CustomMainColorButton>
                                    <div></div>
                                </div>
                            )}
                            <ActionColumnVatRate
                                setVatRateId={setVatRateId}
                                userID={userID}
                                franchiseID={franchiseID}
                                selectedIds={selectedIds}
                                setIsModalOpened={setIsModalOpened}
                                isModalOpened={isModalOpened}
                                setIsHistoryModalOpened={
                                    setIsHistoryModalOpened
                                }
                                isHistoryModalOpened={isHistoryModalOpened}
                                selectedRows={selectedRows}
                                typeDisplayShop={typeDisplayShop}
                                setTypeDisplayShop={setTypeDisplayShop}
                                setLimit={setLimit}
                            />
                        </React.Fragment>
                    }
                    canResize
                    actionColumn={ActionColumn}
                    showGlobalFilter
                    showFilter
                    setLocalFilterActive={setLocalFilterActive}
                    filterActive={filterActive}
                    arrayOfCustomColumns={arrayOfCustomColumnsMultiShop}
                    setDataIsUpdated={
                        setDataIsUpdatedShops as
                            | React.Dispatch<
                                  React.SetStateAction<number | boolean>
                              >
                            | undefined
                    }
                    dataIsUpdated={dataIsUpdatedShops}
                    elevationTable={1}
                    minHeight={oneShop ? "500px" : "450px"}
                    maxHeight={oneShop ? "500px" : "450px"}
                    name="VatRateBoutique"
                />
            </div>

            {isInInitializationModalOpened === true ? (
                <ConfirmationInitializationMessage
                    isModalOpened={isInInitializationModalOpened}
                    setIsModalOpened={setIsInInitializationModalOpened}
                    setIsClicked={setIsAddNewVatRateButtonClicked}
                    isClicked={isAddNewVatRateButtonClicked}
                    setIsEdited={setIsEdited}
                />
            ) : null}

            {isAddVatRateOriginHistoryShopClicked && (
                <OriginHistory
                    setIsAddVatRateOriginHistoryClicked={
                        setIsAddVatRateOriginHistoryShopClicked
                    }
                    isAddVatRateOriginHistoryClicked={
                        isAddVatRateOriginHistoryShopClicked
                    }
                />
            )}

            <div>
                <VatRateAssociateModal
                    setLimit={setLimit}
                    selectedRowsShop={selectedRows}
                />
            </div>

            <MessageConfirmation
                isModalOpened={isModalMessageConfirmationOpened}
                setIsModalOpened={setIsModalMessageConfirmationOpened}
            />
            {activationDeactivationMessageModalOpened && !oneShop && (
                <ConfirmationActivationDeactivationMessageModal />
            )}
        </React.Fragment>
    );
}
