import React, { useState } from "react";
import { toast } from "react-toastify";
import { mutate } from "swr";
import { CheckboxIcon, VerticalDotsIcon } from "@aureskonnect/react-ui";
import { isExists } from "../VatRates/ActionColumnVatRate";
import classnames from "classnames";
import { useTranslation } from "react-i18next/";
import { useSnapshot } from "valtio";

import ErrorToast from "@components/Common/ErrorTost";

import {
    storeVatRate,
    setUrlHistory,
    setIsAddVatRateOriginHistoryClicked,
    setListLinks,
    setDataIsUpdatedFranchise,
    setConfirmationActivateClick,
    setFnDelete,
    setIsDeleteCheckClick,
    setActions,
    setIsActionsClicked,
    setIsEdited,
} from "../store";
import { store } from "../../../../components/VerticalLayout/store";

type actionType = {
    selectedIds: any;
    userID: number;
    franchiseID: string;
    selectedRows: any;
    franchise: boolean;
    typeDisplay: string;
    setTypeDisplay: Function;
    getInfoNumberVatRate: Function;
    setIsAddVatRateClicked: Function;
    setLimit: Function;
};
export default function DropdownActionList({
    selectedIds,
    userID,
    franchiseID,
    selectedRows,
    franchise,
    typeDisplay,
    setTypeDisplay,
    getInfoNumberVatRate,
    setIsAddVatRateClicked,
    setLimit,
}: actionType) {
    const { t } = useTranslation();
    const { oneShop, shopID, operatorID } = useSnapshot(store);
    const {
        isAddVatRateOriginHistoryClicked,
        ListNumberOrigin,
        dataShops,
        dataFranchise,
        isEdited,
        isActionsClicked,
        modify,
    } = useSnapshot(storeVatRate);
    const uuidUser = localStorage.getItem("uuidUser");
    const [canUnarchiveWithShop, setCanUnarchiveWithShop] = useState<boolean>(
        false
    );
    React.useEffect(() => {
        setCanUnarchiveWithShop(
            dataFranchise?.some(
                (item: any) =>
                    selectedIds?.includes(item?.Id) && item?.archived === true
            ) &&
                oneShop === true &&
                typeDisplay === "archived"
        );
    }, [selectedIds, dataFranchise, oneShop, typeDisplay]);
    async function archiveVatRate(
        listLinks: any,
        ids: any,
        isSomeFavorite?: boolean
    ) {
        setLimit(2);
        let message =
            typeDisplay === "notArchived"
                ? t("The vat rate is successfully archived")
                : t("The vat rate is successfully restored");
        let OtherSiteArray: any = [];
        let OtherSiteArrayShopHasArchived: any = [];
        if (franchise || oneShop) {
            let select: any;
            if (typeDisplay === "notArchived") {
                select = false;
                dataShops.forEach((el: any) => {
                    if (el.subRows.length > 0) {
                        if (oneShop === false) {
                            el.subRows.forEach((elx: any) => {
                                ids.forEach((Id: string) => {
                                    if (
                                        elx.Id === Id &&
                                        elx.archived === select
                                    ) {
                                        OtherSiteArray.push(Id);
                                    }
                                });
                            });
                        }
                    }
                });
            } else {
                select = true;
                dataShops.forEach((el: any) => {
                    if (el.subRows.length > 0) {
                        el.subRows.forEach((elx: any) => {
                            ids.forEach((Id: string) => {
                                if (elx.Id === Id && elx.archived === true) {
                                    OtherSiteArrayShopHasArchived.push({
                                        Id: elx.Id,
                                        shopId: elx.shopId,
                                    });
                                }
                            });
                        });
                    }
                });
            }
        } else {
            let select: any;
            if (typeDisplay === "notArchived") {
                select = false;
                if (dataFranchise.length > 0) {
                    dataFranchise.forEach((el: any) => {
                        ids.forEach((Id: string) => {
                            if (el.id === Id && el.archived === select) {
                                OtherSiteArray.push(Id);
                            }
                        });
                    });
                }
            } else {
                select = true;
                if (dataFranchise.length > 0) {
                    dataFranchise.forEach((el: any) => {
                        ids.forEach((Id: string) => {
                            if (el.id === Id && el.archived === select) {
                                OtherSiteArray.push(Id);
                            }
                        });
                    });
                }
            }
        }

        if (isExists(OtherSiteArray, ids) !== -1 && oneShop === false) {
            ErrorToast(
                `${t(
                    "Please note that you cannot archive an item assigned to stores."
                )!}`
            );
        } else {
            if (OtherSiteArrayShopHasArchived.length > 0 && franchise) {
                let listLink: any = [];
                OtherSiteArrayShopHasArchived.forEach((elx: any) => {
                    listLink.push(
                        `${process.env.REACT_APP_API_V2_URL}/settings/general/vatRateArchiveAssocEdit?userId=${userID}&archived=false&franchiseId=${franchiseID}&idVatRate=${elx.Id}&shopId=${elx.shopId}&operatorId=${operatorID}&uuidUser=${uuidUser}`
                    );
                });
                setListLinks([...listLinks, ...listLink]);

                setConfirmationActivateClick(true);
            } else {
                if (typeDisplay === "notArchived") {
                    setFnDelete(async () => {
                        try {
                            for (let i = 0; i < listLinks.length; i++) {
                                let el = listLinks[i];

                                await mutate(
                                    el,
                                    await fetch(el, {
                                        headers: {
                                            "Content-Type": "application/json",
                                            authorization: `Bearer ${localStorage.getItem(
                                                "jwt"
                                            )}`,
                                        },
                                        method: "DELETE",
                                    })
                                        .then((response) => response.json())
                                        .then((data) => {
                                            if (data.error) {
                                                throw Error(
                                                    "Error while delete selection!"
                                                );
                                            }
                                            if (i === listLinks.length - 1) {
                                                setDataIsUpdatedFranchise!(
                                                    true
                                                );
                                                toast.success(message, {
                                                    position:
                                                        toast.POSITION
                                                            .TOP_CENTER,
                                                    autoClose: 2000,
                                                    theme: "colored",
                                                    closeOnClick: true,
                                                    pauseOnHover: true,
                                                    draggable: true,
                                                    icon: (
                                                        <CheckboxIcon
                                                            height={25}
                                                            width={25}
                                                            fill="white"
                                                        />
                                                    ),
                                                });
                                            }
                                        })
                                );
                            }
                            if (isSomeFavorite === true) {
                                ErrorToast(
                                    t(
                                        "Please note, you cannot archive a favorite tva"
                                    )
                                );
                            }
                        } catch (e) {}
                    });
                    setIsDeleteCheckClick(true);
                } else {
                    try {
                        for (let i = 0; i < listLinks.length; i++) {
                            let el = listLinks[i];

                            await mutate(
                                el,
                                await fetch(el, {
                                    headers: {
                                        "Content-Type": "application/json",
                                        authorization: `Bearer ${localStorage.getItem(
                                            "jwt"
                                        )}`,
                                    },
                                    method: "DELETE",
                                })
                                    .then((response) => response.json())
                                    .then((data) => {
                                        if (data.error) {
                                            throw Error(
                                                "Error while delete selection!"
                                            );
                                        }
                                        if (i === listLinks.length - 1) {
                                            setDataIsUpdatedFranchise!(true);
                                            toast.success(message, {
                                                position:
                                                    toast.POSITION.TOP_CENTER,
                                                autoClose: 2000,
                                                theme: "colored",
                                                closeOnClick: true,
                                                pauseOnHover: true,
                                                draggable: true,
                                                icon: (
                                                    <CheckboxIcon
                                                        height={25}
                                                        width={25}
                                                        fill="white"
                                                    />
                                                ),
                                            });
                                        }
                                    })
                            );
                        }
                    } catch (e) {}
                }
            }
        }
    }

    return (
        <React.Fragment>
            <div>
                <div
                    className={` cmn_drp_FRmenu ${classnames("dropdown", {})}`}
                    onClick={() => {
                        getInfoNumberVatRate();
                    }}
                >
                    <VerticalDotsIcon
                        id="dropdownMenuButton1"
                        data-bs-toggle="dropdown"
                        style={{ cursor: "pointer" }}
                        height={25}
                        width={25}
                        fill="black"
                    />
                    <div
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuButton"
                    >
                        <div
                            className="dropdown-item pl-1 cmn_btn_FRlistActivated"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                                if (isEdited === true) {
                                    setIsActionsClicked(!isActionsClicked);
                                    setActions(() => {
                                        setIsEdited(false);
                                        setTypeDisplay("notArchived");
                                        setIsAddVatRateClicked!(false);
                                    });
                                } else {
                                    setTypeDisplay("notArchived");
                                }
                            }}
                        >
                            <span className="ml-2">
                                {t("Activated VAT rate(s)")} (
                                {ListNumberOrigin.nbrNotArchived})
                            </span>
                        </div>
                        <div
                            className="dropdown-item pl-1 cmn_btn_FRlistArchived"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                                if (isEdited === true) {
                                    setIsActionsClicked(!isActionsClicked);
                                    setActions(() => {
                                        setIsEdited(false);
                                        setTypeDisplay("archived");
                                        setIsAddVatRateClicked!(false);
                                    });
                                } else {
                                    setTypeDisplay("archived");
                                }
                            }}
                        >
                            <span className="ml-2">
                                {t("Archived vat rate(s)")} (
                                {ListNumberOrigin.nbrArchived})
                            </span>
                        </div>
                        <div
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                                if (isEdited === true) {
                                    setIsActionsClicked(!isActionsClicked);
                                    setActions(() => {
                                        setIsEdited(false);
                                        var str = selectedIds.join(",");
                                        oneShop
                                            ? setUrlHistory(
                                                  `${process.env.REACT_APP_API_V2_URL}/settings/general/vatRate/origin_history?userId=${userID}&franchiseId=${franchiseID}&typeDisplay=all&idVatRates=${str}`
                                              )
                                            : setUrlHistory(
                                                  `${process.env.REACT_APP_API_V2_URL}/settings/general/vatRate/origin_history?userId=${userID}&franchiseId=${franchiseID}&typeDisplay=all&idVatRates=${str}`
                                              );
                                        if (selectedIds.length === 1) {
                                            ErrorToast(
                                                t(
                                                    "Attention, it is a multiple action. Please select at least two items"
                                                )
                                            );
                                        } else {
                                            setIsAddVatRateOriginHistoryClicked(
                                                !isAddVatRateOriginHistoryClicked
                                            );
                                        }
                                        setIsAddVatRateClicked!(false);
                                    });
                                } else {
                                    var str = selectedIds.join(",");
                                    oneShop
                                        ? setUrlHistory(
                                              `${process.env.REACT_APP_API_V2_URL}/settings/general/vatRate/origin_history?userId=${userID}&franchiseId=${franchiseID}&typeDisplay=all&idVatRates=${str}`
                                          )
                                        : setUrlHistory(
                                              `${process.env.REACT_APP_API_V2_URL}/settings/general/vatRate/origin_history?userId=${userID}&franchiseId=${franchiseID}&typeDisplay=all&idVatRates=${str}`
                                          );
                                    if (selectedIds.length === 1) {
                                        ErrorToast(
                                            t(
                                                "Attention, it is a multiple action. Please select at least two items"
                                            )
                                        );
                                    } else {
                                        setIsAddVatRateOriginHistoryClicked(
                                            !isAddVatRateOriginHistoryClicked
                                        );
                                    }
                                }
                            }}
                            className={`${classnames(
                                "cmn_btn_FRhistory dropdown-item pl-1 pointer__clz ",
                                {
                                    "not-allowed-icon__clz":
                                        selectedIds !== undefined &&
                                        selectedIds.length === 0,
                                }
                            )}`}
                        >
                            <span className="ml-2">{t("Historical")}</span>
                        </div>
                        {modify === true && (
                            <div
                                style={{ cursor: "pointer" }}
                                className={`cmn_btn_FRarchived cmn_btn_FRunarchive ${classnames(
                                    " dropdown-item pl-1 pointer__clz",
                                    {
                                        "not-allowed-icon__clz":
                                            (selectedIds !== undefined &&
                                                selectedIds.length === 0 &&
                                                canUnarchiveWithShop ===
                                                    false) ||
                                            canUnarchiveWithShop,
                                    }
                                )}`}
                                onClick={async () => {
                                    let listLinks: any = [];
                                    selectedIds.forEach((elx: string) => {
                                        listLinks.push(
                                            `${
                                                process.env.REACT_APP_API_V2_URL
                                            }/settings/general/vatRateArchivedEdit?userId=${userID}&archived=${
                                                typeDisplay === "notArchived"
                                                    ? true
                                                    : false
                                            }&franchiseId=${franchiseID}&idVatRate=${elx}&operatorId=${operatorID}&uuidUser=${uuidUser}`
                                        );
                                    });
                                    setListLinks(listLinks);

                                    setIsAddVatRateClicked!(false);
                                    if (isEdited === true) {
                                        setIsActionsClicked(!isActionsClicked);
                                        setActions(async () => {
                                            setIsEdited(false);
                                            setIsAddVatRateClicked!(false);
                                            if (selectedIds.length <= 1) {
                                                ErrorToast(
                                                    t(
                                                        "Attention, it is a multiple action. Please select at least two items"
                                                    )
                                                );
                                            } else {
                                                let listLinks: any = [];
                                                let ids: any = [];
                                                selectedRows.forEach(
                                                    (elx: any) => {
                                                        if (
                                                            elx[t("Shop")] ===
                                                                undefined ||
                                                            elx[t("Shop")] ===
                                                                ""
                                                        ) {
                                                            ids.push(elx.Id);
                                                            listLinks.push(
                                                                `${
                                                                    process.env
                                                                        .REACT_APP_API_V2_URL
                                                                }/settings/general/${
                                                                    franchise
                                                                        ? "vatRateArchivedEdit"
                                                                        : "vatRateArchiveAssocEdit"
                                                                }?userId=${userID}&archived=${
                                                                    typeDisplay ===
                                                                    "notArchived"
                                                                        ? true
                                                                        : false
                                                                }&franchiseId=${franchiseID}&idVatRate=${
                                                                    elx.Id
                                                                }${
                                                                    franchise
                                                                        ? ""
                                                                        : `&shopId=${shopID}`
                                                                }&operatorId=${operatorID}&uuidUser=${uuidUser}`
                                                            );
                                                        }
                                                    }
                                                );
                                                setListLinks(listLinks);

                                                await archiveVatRate(
                                                    listLinks,
                                                    ids
                                                );
                                            }
                                        });
                                    } else {
                                        if (selectedIds.length <= 1) {
                                            ErrorToast(
                                                t(
                                                    "Attention, it is a multiple action. Please select at least two items"
                                                )
                                            );
                                        } else {
                                            let listLinks: any = [];
                                            let ids: any = [];
                                            let isSomeFavorite = false;

                                            selectedRows.forEach((elx: any) => {
                                                if (elx.isFavorite === true) {
                                                    isSomeFavorite = true;
                                                } else if (
                                                    elx[t("Shop")] ===
                                                        undefined ||
                                                    elx[t("Shop")] === ""
                                                ) {
                                                    ids.push(elx.Id);
                                                    listLinks.push(
                                                        `${
                                                            process.env
                                                                .REACT_APP_API_V2_URL
                                                        }/settings/general/${
                                                            franchise
                                                                ? "vatRateArchivedEdit"
                                                                : "vatRateArchiveAssocEdit"
                                                        }?userId=${userID}&archived=${
                                                            typeDisplay ===
                                                            "notArchived"
                                                                ? true
                                                                : false
                                                        }&franchiseId=${franchiseID}&idVatRate=${
                                                            elx.Id
                                                        }${
                                                            franchise
                                                                ? ""
                                                                : `&shopId=${shopID}`
                                                        }&operatorId=${operatorID}&uuidUser=${uuidUser}`
                                                    );
                                                }
                                            });
                                            setListLinks(listLinks);

                                            await archiveVatRate(
                                                listLinks,
                                                ids,
                                                isSomeFavorite
                                            );
                                        }
                                    }
                                }}
                            >
                                <span className="ml-2">
                                    {typeDisplay === "notArchived"
                                        ? t("Archive")
                                        : t("Dearchive")}
                                </span>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
