import React from "react";
import classnames from "classnames";
import ReactSwitch from "react-switch";
import { Tooltip } from "@mui/material";
import { HeartIcon } from "@aureskonnect/react-ui";
import { useSnapshot } from "valtio";
import { useTranslation } from "react-i18next";

import {
    setListAfterAssociatedVatRate,
    setTvaFavored,
    storeVatRate,
} from "../store";
import { searchPosInArrayObject } from "@helpers/general";
import ErrorToast from "@components/Common/ErrorTost";

export function CustomIconsAssociateModal({
    e,
    setData,
    isOneShopClicked,
}: any) {
    const { t } = useTranslation();

    const {
        isVatRateArchived,
        listAfterAssociatedVatRate,
        associatedShops,
        dataShops,
        tvaFavored,
        associatedTva,
    } = useSnapshot(storeVatRate);

    let posShop = searchPosInArrayObject(
        dataShops,
        "shopId",
        associatedShops[0]?.shopId
    );
    let shopsVatRateOrigin: any = [];
    if (posShop !== -1) {
        shopsVatRateOrigin = dataShops[posShop].subRows;
    }

    let shopsVatRate: any = [];
    shopsVatRateOrigin.forEach((elx: any) => {
        if (elx.archived === false) {
            shopsVatRate.push(elx);
        }
    });

    const [VatRateState, setVatRateState] = React.useState<boolean>(
        e.selectedRow.status
    );

    async function handleStateChange(id: string, value: boolean) {
        let assocAux = JSON.parse(JSON.stringify(listAfterAssociatedVatRate));
        var pos = searchPosInArrayObject(assocAux, "Id", id);
        let objAC = assocAux[pos];
        objAC.status = value;
        assocAux[pos] = objAC;
        setListAfterAssociatedVatRate(assocAux);
    }

    React.useEffect(() => {
        isOneShopClicked === true
            ? setTvaFavored({
                  id: e.selectedRow.Id,
                  status:
                      associatedTva.findIndex(
                          (el: any) => el.Id === e.selectedRow.Id
                      ) !== -1
                          ? associatedTva[
                                associatedTva.findIndex(
                                    (el: any) => el.Id === e.selectedRow.Id
                                )
                            ].isFavorite
                          : false,
              })
            : setTvaFavored({
                  id: e.selectedRow.Id,
                  status: false,
              });
    }, [associatedTva, e.selectedRow.Id, isOneShopClicked]);

    return (
        <React.Fragment>
            {e.selectedRow?.subRows === undefined ? (
                <div className="d-flex justify-content-around">
                    <Tooltip
                        title={
                            tvaFavored.find(
                                (el: any) => el.id === e.selectedRow.Id
                            ) !== undefined &&
                            tvaFavored.find(
                                (el: any) => el.id === e.selectedRow.Id
                            ).status
                                ? ""
                                : t("Enable favorite state")
                        }
                    >
                        <div>
                            <HeartIcon
                                onClick={() => {
                                    if (
                                        !(
                                            tvaFavored.find(
                                                (el: any) =>
                                                    el.id === e.selectedRow.Id
                                            ) !== undefined &&
                                            tvaFavored.find(
                                                (el: any) =>
                                                    el.id === e.selectedRow.Id
                                            ).status
                                        )
                                    ) {
                                        if (!VatRateState) {
                                            ErrorToast(
                                                t(
                                                    "Please note, you cannot activate the favorite state for a disabled tva"
                                                )
                                            );
                                        } else {
                                            setTvaFavored({
                                                id: e.selectedRow.Id,
                                                status:
                                                    tvaFavored.find(
                                                        (el: any) =>
                                                            el.id ===
                                                            e.selectedRow.Id
                                                    ) !== undefined &&
                                                    !tvaFavored.find(
                                                        (el: any) =>
                                                            el.id ===
                                                            e.selectedRow.Id
                                                    ).status,
                                            });
                                        }
                                    }
                                }}
                                height={25}
                                width={25}
                                name="heart"
                                fill={
                                    tvaFavored.find(
                                        (el: any) => el.id === e.selectedRow.Id
                                    ) !== undefined &&
                                    tvaFavored.find(
                                        (el: any) => el.id === e.selectedRow.Id
                                    ).status
                                        ? "red"
                                        : "black"
                                }
                                className={`${classnames(
                                    "mdv_icn_AFlike cmn_icn_AFlike",
                                    {
                                        pointer__clz: !(
                                            tvaFavored.find(
                                                (el: any) =>
                                                    el.id === e.selectedRow.Id
                                            ) !== undefined &&
                                            tvaFavored.find(
                                                (el: any) =>
                                                    el.id === e.selectedRow.Id
                                            ).status
                                        ),
                                    }
                                )}`}
                            />
                        </div>
                    </Tooltip>
                    <Tooltip
                        title={
                            VatRateState === false
                                ? t("Activate")
                                : t("Unactivate")
                        }
                    >
                        <div>
                            <ReactSwitch
                                uncheckedIcon={false}
                                checkedIcon={false}
                                handleDiameter={26}
                                offColor="#f7b4b8"
                                offHandleColor="#E30613"
                                checked={VatRateState}
                                onChange={async () => {
                                    if (e.selectedRow.isFavorite === true) {
                                        ErrorToast(
                                            t(
                                                "Please note, you cannot deactivate a favorite VAT"
                                            )
                                        );
                                    } else {
                                        setVatRateState(!VatRateState);
                                        let pos = searchPosInArrayObject(
                                            listAfterAssociatedVatRate,
                                            "Id",
                                            e.selectedRow.Id
                                        );

                                        let objData = JSON.parse(
                                            JSON.stringify(
                                                listAfterAssociatedVatRate
                                            )
                                        );
                                        objData[pos].status = !VatRateState;

                                        setData({
                                            data: objData,
                                            othersData: [],
                                        });

                                        handleStateChange(
                                            e.selectedRow.Id,
                                            !VatRateState
                                        );
                                    }
                                }}
                                onColor="#c2eddd"
                                onHandleColor="#34C38F"
                                width={50}
                                height={20}
                                className={`${classnames(
                                    "pointer__clz ml-3 tva_icn_AFenableDisable",
                                    {
                                        "not-allowed-icon__clz": isVatRateArchived,
                                    }
                                )}`}
                            />
                        </div>
                    </Tooltip>
                </div>
            ) : null}
        </React.Fragment>
    );
}
