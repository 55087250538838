import React from "react";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";

import ReactSwitch from "react-switch";
import {
    generalConfigStore,
    setIsEnterOrScanPromoCode,
    setIsGift,
    setIsRewards,
    setIsPaymentWithLoyaltyBalance,
    setIsAutoLaunchOnOrderTaking,
    setIsAutoLaunchOnPayButton,
    setIsStateChanged,
    setIsPagePromoCodeActive,
} from "@store";
import { store } from "@components/VerticalLayout/store";
import { GODIA_LOYALTY } from "@constants/index";

export function CustomerAccountOptions(): JSX.Element {
    const { t } = useTranslation();
    const { project } = useSnapshot(generalConfigStore);
    const { fideltyType } = useSnapshot(store);

    const isEnterOrScanPromoCode = ((((project.template.pages.subSteps[
        "customerBenefit"
    ] as unknown) as CustomerBenefitPageType)
        .options as CustomerBenefitOptionsType).promoCode as RewardType).active;
    const isGift = ((((project.template.pages.subSteps[
        "customerBenefit"
    ] as unknown) as CustomerBenefitPageType)
        .options as CustomerBenefitOptionsType).gifts as RewardType).active;
    const isRewards = ((((project.template.pages.subSteps[
        "customerBenefit"
    ] as unknown) as CustomerBenefitPageType)
        .options as CustomerBenefitOptionsType).rewards as RewardType).active;
    const isPaymentWithLoyaltyBalance = (((project.template.pages.subSteps[
        "customerBenefit"
    ] as unknown) as CustomerBenefitPageType)
        .options as CustomerBenefitOptionsType).paymentWithLoyaltyBalance;
    // const isCumulativeAdvantage = (((project.template.pages.subSteps[
    //     "customerBenefit"
    // ] as unknown) as CustomerBenefitPageType)
    //     .options as CustomerBenefitOptionsType).cumulativeAdvantage;
    // const isAccountCreation = (((project.template.pages.subSteps[
    //     "customerBenefit"
    // ] as unknown) as CustomerBenefitPageType)
    //     .options as CustomerBenefitOptionsType).accountCreation;
    const isAutoLaunchOnOrderTaking = (((project.template.pages.subSteps[
        "customerBenefit"
    ] as unknown) as CustomerBenefitPageType)
        .options as CustomerBenefitOptionsType).autoLaunchOnOrderTaking;
    const isAutoLaunchOnPayButton = (((project.template.pages.subSteps[
        "customerBenefit"
    ] as unknown) as CustomerBenefitPageType)
        .options as CustomerBenefitOptionsType).autoLaunchOnPayButton;

    function activeCustomerAccountOptionsSwitchOnClickEvent(
        setState: Function,
        state: boolean,
        name?: string
    ) {
        setState(!state);

        if (
            fideltyType[`${project.shopId}`] === GODIA_LOYALTY &&
            name &&
            [
                "isEnterOrScanPromoCode",
                "isGift",
                "isRewards",
                "isPaymentWithLoyaltyBalance",
            ].includes(name)
        ) {
            if (name !== "isPaymentWithLoyaltyBalance") {
                let isEnterOrScanPromoCodeIsGift =
                    isEnterOrScanPromoCode && isGift;

                const newIsRewards = name === "isRewards" ? !state : isRewards;
                if (name === "isEnterOrScanPromoCode" || name === "isGift") {
                    isEnterOrScanPromoCodeIsGift = !state;
                    setIsGift(!state);
                    setIsEnterOrScanPromoCode(!state);
                }

                // setIsPaymentWithLoyaltyBalance(!isEnterOrScanPromoCodeIsGift && !newIsRewards);
                setIsPagePromoCodeActive(
                    isEnterOrScanPromoCodeIsGift || newIsRewards
                );
            } else {
                setIsEnterOrScanPromoCode(state);
                setIsGift(state);
                setIsRewards(state);
                setIsPagePromoCodeActive(state);
            }
            setIsStateChanged(true);
        }
    }

    return (
        <React.Fragment>
            <div className="d-flex flex-column pr-4">
                <div className="d-flex align-items-center mt-3">
                    <span
                        style={{
                            font: "normal normal 600 35px/61px Nunito Sans",
                        }}
                    >
                        {t("Options")}
                    </span>
                </div>

                <div
                    className="d-flex flex-column pl-4"
                    style={{ gap: "10px" }}
                >
                    {fideltyType[`${project.shopId}`] === GODIA_LOYALTY ? (
                        <>
                            {" "}
                            <div className="d-flex flex-row justify-content-between align-items-center">
                                {t("Scan or enter a promo code")}

                                <ReactSwitch
                                    className="ksk_chk_expertTicketDematerialize"
                                    uncheckedIcon={false}
                                    checkedIcon={false}
                                    handleDiameter={26}
                                    offColor="#f7b4b8"
                                    offHandleColor="#E30613"
                                    checked={isEnterOrScanPromoCode}
                                    onChange={() => {
                                        activeCustomerAccountOptionsSwitchOnClickEvent(
                                            setIsEnterOrScanPromoCode,
                                            isEnterOrScanPromoCode,
                                            "isEnterOrScanPromoCode"
                                        );
                                    }}
                                    onColor="#c2eddd"
                                    onHandleColor="#34C38F"
                                    width={50}
                                    height={20}
                                />
                            </div>
                            <div className="d-flex flex-row justify-content-between align-items-center">
                                {t("Gifts")}

                                <ReactSwitch
                                    className="ksk_chk_expertTicketDematerialize"
                                    uncheckedIcon={false}
                                    checkedIcon={false}
                                    handleDiameter={26}
                                    offColor="#f7b4b8"
                                    offHandleColor="#E30613"
                                    checked={isGift}
                                    onChange={() => {
                                        activeCustomerAccountOptionsSwitchOnClickEvent(
                                            setIsGift,
                                            isGift,
                                            "isGift"
                                        );
                                    }}
                                    onColor="#c2eddd"
                                    onHandleColor="#34C38F"
                                    width={50}
                                    height={20}
                                />
                            </div>
                            <div className="d-flex flex-row justify-content-between align-items-center">
                                {t("Rewards")}

                                <ReactSwitch
                                    className="ksk_chk_expertTicketDematerialize"
                                    uncheckedIcon={false}
                                    checkedIcon={false}
                                    handleDiameter={26}
                                    offColor="#f7b4b8"
                                    offHandleColor="#E30613"
                                    checked={isRewards}
                                    onChange={() => {
                                        activeCustomerAccountOptionsSwitchOnClickEvent(
                                            setIsRewards,
                                            isRewards,
                                            "isRewards"
                                        );
                                    }}
                                    onColor="#c2eddd"
                                    onHandleColor="#34C38F"
                                    width={50}
                                    height={20}
                                />
                            </div>{" "}
                        </>
                    ) : null}
                    <div className="d-flex flex-row justify-content-between align-items-center">
                        {t("Payment with loyalty balance")}

                        <ReactSwitch
                            className="ksk_chk_expertTicketDematerialize"
                            uncheckedIcon={false}
                            checkedIcon={false}
                            handleDiameter={26}
                            offColor="#f7b4b8"
                            offHandleColor="#E30613"
                            checked={isPaymentWithLoyaltyBalance}
                            onChange={() => {
                                activeCustomerAccountOptionsSwitchOnClickEvent(
                                    setIsPaymentWithLoyaltyBalance,
                                    isPaymentWithLoyaltyBalance,
                                    "isPaymentWithLoyaltyBalance"
                                );
                            }}
                            onColor="#c2eddd"
                            onHandleColor="#34C38F"
                            width={50}
                            height={20}
                            disabled={
                                fideltyType[`${project.shopId}`] ===
                                GODIA_LOYALTY
                                    ? true
                                    : false
                            }
                        />
                    </div>
                    {/* {
    fideltyType !== GODIA_LOYALTY &&

                 (   <><hr className="w-100" /><div className="d-flex flex-row justify-content-between align-items-center">
                            {t("Cumulative benefits")}

                            <ReactSwitch
                                className="ksk_chk_expertAuthSendTicketInEmail"
                                uncheckedIcon={false}
                                checkedIcon={false}
                                handleDiameter={26}
                                offColor="#f7b4b8"
                                offHandleColor="#E30613"
                                checked={isCumulativeAdvantage}
                                onChange={() => {
                                    activeCustomerAccountOptionsSwitchOnClickEvent(
                                        setIsCumulativeAdvantage,
                                        isCumulativeAdvantage
                                    );
                                } }
                                onColor="#c2eddd"
                                onHandleColor="#34C38F"
                                width={50}
                                height={20}
                                disabled={false} />
                        </div><div className="d-flex flex-row justify-content-between align-items-center">
                                {t("Account creation")}

                                <ReactSwitch
                                    className="ksk_chk_expertAuthCheckEmail"
                                    uncheckedIcon={false}
                                    checkedIcon={false}
                                    handleDiameter={26}
                                    offColor="#f7b4b8"
                                    offHandleColor="#E30613"
                                    checked={isAccountCreation}
                                    onChange={() => {
                                        activeCustomerAccountOptionsSwitchOnClickEvent(
                                            setIsAccountCreation,
                                            isAccountCreation
                                        );
                                    } }
                                    onColor="#c2eddd"
                                    onHandleColor="#34C38F"
                                    width={50}
                                    height={20}
                                    disabled={false} />
                            </div></>) } */}

                    {fideltyType[`${project.shopId}`] === GODIA_LOYALTY && (
                        <>
                            <hr className="w-100" />
                            <span
                                style={{
                                    font:
                                        "normal normal 600 24px/40px Nunito Sans",
                                }}
                            >
                                {t(
                                    "Automatic triggering customer benefit interface"
                                )}
                            </span>
                            <div className="d-flex flex-row justify-content-between align-items-center">
                                {t(
                                    "When the order taking interface is launched"
                                )}

                                <ReactSwitch
                                    className="ksk_chk_expertNotAuthSendEmail"
                                    uncheckedIcon={false}
                                    checkedIcon={false}
                                    handleDiameter={26}
                                    offColor="#f7b4b8"
                                    offHandleColor="#E30613"
                                    checked={isAutoLaunchOnOrderTaking}
                                    onChange={() => {
                                        activeCustomerAccountOptionsSwitchOnClickEvent(
                                            setIsAutoLaunchOnOrderTaking,
                                            isAutoLaunchOnOrderTaking
                                        );
                                    }}
                                    onColor="#c2eddd"
                                    onHandleColor="#34C38F"
                                    width={50}
                                    height={20}
                                    disabled={false}
                                />
                            </div>
                            <div className="d-flex flex-row justify-content-between align-items-center">
                                {t("Click on the pay button")}

                                <ReactSwitch
                                    className="ksk_chk_expertNotAuthSendEmail"
                                    uncheckedIcon={false}
                                    checkedIcon={false}
                                    handleDiameter={26}
                                    offColor="#f7b4b8"
                                    offHandleColor="#E30613"
                                    checked={isAutoLaunchOnPayButton}
                                    onChange={() => {
                                        activeCustomerAccountOptionsSwitchOnClickEvent(
                                            setIsAutoLaunchOnPayButton,
                                            isAutoLaunchOnPayButton
                                        );
                                    }}
                                    onColor="#c2eddd"
                                    onHandleColor="#34C38F"
                                    width={50}
                                    height={20}
                                    disabled={false}
                                />
                            </div>
                        </>
                    )}
                </div>
            </div>
        </React.Fragment>
    );
}
